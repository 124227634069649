const ContentType = {
    Component: 0,
    Helper: 1,
    String: 2,
    Empty: 3,
    SafeString: 4,
    Fragment: 5,
    Node: 6,
    Other: 8
  },
  CurriedTypes = {
    Component: 0,
    Helper: 1,
    Modifier: 2
  },
  InternalComponentCapabilities = {
    Empty: 0,
    dynamicLayout: 1,
    dynamicTag: 2,
    prepareArgs: 4,
    createArgs: 8,
    attributeHook: 16,
    elementHook: 32,
    dynamicScope: 64,
    createCaller: 128,
    updateHook: 256,
    createInstance: 512,
    wrapped: 1024,
    willDestroy: 2048,
    hasSubOwner: 4096
  },
  ARG_SHIFT = 8,
  MAX_SIZE = 2147483647,
  TYPE_SIZE = 255,
  TYPE_MASK = 255,
  OPERAND_LEN_MASK = 768,
  MACHINE_MASK = 1024,
  MachineOp = {
    PushFrame: 0,
    PopFrame: 1,
    InvokeVirtual: 2,
    InvokeStatic: 3,
    Jump: 4,
    Return: 5,
    ReturnTo: 6,
    Size: 7
  },
  Op = {
    Helper: 16,
    SetNamedVariables: 17,
    SetBlocks: 18,
    SetVariable: 19,
    SetBlock: 20,
    GetVariable: 21,
    GetProperty: 22,
    GetBlock: 23,
    SpreadBlock: 24,
    HasBlock: 25,
    HasBlockParams: 26,
    Concat: 27,
    Constant: 28,
    ConstantReference: 29,
    Primitive: 30,
    PrimitiveReference: 31,
    ReifyU32: 32,
    Dup: 33,
    Pop: 34,
    Load: 35,
    Fetch: 36,
    RootScope: 37,
    VirtualRootScope: 38,
    ChildScope: 39,
    PopScope: 40,
    Text: 41,
    Comment: 42,
    AppendHTML: 43,
    AppendSafeHTML: 44,
    AppendDocumentFragment: 45,
    AppendNode: 46,
    AppendText: 47,
    OpenElement: 48,
    OpenDynamicElement: 49,
    PushRemoteElement: 50,
    StaticAttr: 51,
    DynamicAttr: 52,
    ComponentAttr: 53,
    FlushElement: 54,
    CloseElement: 55,
    PopRemoteElement: 56,
    Modifier: 57,
    BindDynamicScope: 58,
    PushDynamicScope: 59,
    PopDynamicScope: 60,
    CompileBlock: 61,
    PushBlockScope: 62,
    PushSymbolTable: 63,
    InvokeYield: 64,
    JumpIf: 65,
    JumpUnless: 66,
    JumpEq: 67,
    AssertSame: 68,
    Enter: 69,
    Exit: 70,
    ToBoolean: 71,
    EnterList: 72,
    ExitList: 73,
    Iterate: 74,
    Main: 75,
    ContentType: 76,
    Curry: 77,
    PushComponentDefinition: 78,
    PushDynamicComponentInstance: 79,
    ResolveDynamicComponent: 80,
    ResolveCurriedComponent: 81,
    PushArgs: 82,
    PushEmptyArgs: 83,
    PopArgs: 84,
    PrepareArgs: 85,
    CaptureArgs: 86,
    CreateComponent: 87,
    RegisterComponentDestructor: 88,
    PutComponentOperations: 89,
    GetComponentSelf: 90,
    GetComponentTagName: 91,
    GetComponentLayout: 92,
    BindEvalScope: 93,
    SetupForEval: 94,
    PopulateLayout: 95,
    InvokeComponentLayout: 96,
    BeginComponentTransaction: 97,
    CommitComponentTransaction: 98,
    DidCreateElement: 99,
    DidRenderLayout: 100,
    ResolveMaybeLocal: 102,
    Debugger: 103,
    Size: 104,
    StaticComponentAttr: 105,
    DynamicContentType: 106,
    DynamicHelper: 107,
    DynamicModifier: 108,
    IfInline: 109,
    Not: 110,
    GetDynamicVar: 111,
    Log: 112
  };
function isMachineOp(value) {
  return value >= 0 && value <= 15;
}
function isOp(value) {
  return value >= 16;
}

/**
 * Registers
 *
 * For the most part, these follows MIPS naming conventions, however the
 * register numbers are different.
 */
// $0 or $pc (program counter): pointer into `program` for the next insturction; -1 means exit
const $pc = 0,
  $ra = 1,
  $fp = 2,
  $sp = 3,
  $s0 = 4,
  $s1 = 5,
  $t0 = 6,
  $t1 = 7,
  $v0 = 8;

// $1 or $ra (return address): pointer into `program` for the return
let MachineRegister = function (MachineRegister) {
  return MachineRegister[MachineRegister.pc = 0] = "pc", MachineRegister[MachineRegister.ra = 1] = "ra", MachineRegister[MachineRegister.fp = 2] = "fp", MachineRegister[MachineRegister.sp = 3] = "sp", MachineRegister;
}({});
function isLowLevelRegister(register) {
  return register <= 3;
}
let SavedRegister = function (SavedRegister) {
    return SavedRegister[SavedRegister.s0 = 4] = "s0", SavedRegister[SavedRegister.s1 = 5] = "s1", SavedRegister;
  }({}),
  TemporaryRegister = function (TemporaryRegister) {
    return TemporaryRegister[TemporaryRegister.t0 = 6] = "t0", TemporaryRegister[TemporaryRegister.t1 = 7] = "t1", TemporaryRegister;
  }({});

export { $fp, $pc, $ra, $s0, $s1, $sp, $t0, $t1, $v0, ARG_SHIFT, ContentType, CurriedTypes as CurriedType, CurriedTypes, InternalComponentCapabilities, InternalComponentCapabilities as InternalComponentCapability, MACHINE_MASK, MAX_SIZE, MachineOp, MachineRegister, OPERAND_LEN_MASK, Op, SavedRegister, TYPE_MASK, TYPE_SIZE, TemporaryRegister, isLowLevelRegister, isMachineOp, isOp };
