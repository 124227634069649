import { assert, scheduleRevalidate } from '../global-context/index.js';
import { getLast, asPresentArray } from '../util/index.js';
import { isDevelopingApp } from '@embroider/macros';

function unwrap(val) {
  if (null == val) throw new Error("Expected value to be present");
  return val;
}
const debug = {};
if (isDevelopingApp()) {
  let CONSUMED_TAGS = null;
  const TRANSACTION_STACK = [],
    TRANSACTION_ENV = {
      debugMessage(obj, keyName) {
        let objName;
        return objName = "function" == typeof obj ? obj.name : "object" == typeof obj && null !== obj ? `(an instance of ${obj.constructor && obj.constructor.name || "(unknown class)"})` : void 0 === obj ? "(an unknown tag)" : String(obj), `You attempted to update ${keyName ? `\`${keyName}\` on \`${objName}\`` : `\`${objName}\``}, but it had already been used previously in the same computation.  Attempting to update a value after using it in a computation can cause logical errors, infinite revalidation bugs, and performance issues, and is not supported.`;
      }
    };
  /////////
  debug.setTrackingTransactionEnv = env => Object.assign(TRANSACTION_ENV, env), debug.beginTrackingTransaction = _debugLabel => {
    CONSUMED_TAGS = CONSUMED_TAGS || new WeakMap();
    let debugLabel = _debugLabel || void 0,
      parent = TRANSACTION_STACK[TRANSACTION_STACK.length - 1] ?? null;
    TRANSACTION_STACK.push({
      parent: parent,
      debugLabel: debugLabel
    });
  }, debug.endTrackingTransaction = () => {
    if (0 === TRANSACTION_STACK.length) throw new Error("attempted to close a tracking transaction, but one was not open");
    TRANSACTION_STACK.pop(), 0 === TRANSACTION_STACK.length && (CONSUMED_TAGS = null);
  }, debug.resetTrackingTransaction = () => {
    let stack = "";
    return TRANSACTION_STACK.length > 0 && (stack = debug.logTrackingStack(TRANSACTION_STACK[TRANSACTION_STACK.length - 1])), TRANSACTION_STACK.splice(0, TRANSACTION_STACK.length), CONSUMED_TAGS = null, stack;
  },
  /**
  * Creates a global autotracking transaction. This will prevent any backflow
  * in any `track` calls within the transaction, even if they are not
  * externally consumed.
  *
  * `runInAutotrackingTransaction` can be called within itself, and it will add
  * onto the existing transaction if one exists.
  *
  * TODO: Only throw an error if the `track` is consumed.
  */
  debug.runInTrackingTransaction = (fn, debugLabel) => {
    debug.beginTrackingTransaction(debugLabel);
    let didError = !0;
    try {
      let value = fn();
      return didError = !1, value;
    } finally {
      !0 !== didError && debug.endTrackingTransaction();
      // if (id !== TRANSACTION_STACK.length) {
      //   throw new Error(
      //     `attempted to close a tracking transaction (${id}), but it was not the last transaction (${TRANSACTION_STACK.length})`
      //   );
      // }
    }
  };
  let nthIndex = (str, pattern, n, startingPos = -1) => {
      let i = startingPos;
      for (; n-- > 0 && i++ < str.length && (i = str.indexOf(pattern, i), !(i < 0)););
      return i;
    },
    makeTrackingErrorMessage = (transaction, obj, keyName) => {
      let message = [TRANSACTION_ENV.debugMessage(obj, keyName && String(keyName))];
      return message.push(`\`${String(keyName)}\` was first used:`), message.push(debug.logTrackingStack(transaction)), message.push("Stack trace for the update:"), message.join("\n\n");
    };
  debug.logTrackingStack = transaction => {
    let trackingStack = [],
      current = transaction || TRANSACTION_STACK[TRANSACTION_STACK.length - 1];
    if (void 0 === current) return "";
    for (; current;) current.debugLabel && trackingStack.unshift(current.debugLabel), current = current.parent;
    return trackingStack.map((label, index) => " ".repeat(2 * index) + label).join("\n");
  }, debug.markTagAsConsumed = _tag => {
    if (!CONSUMED_TAGS || CONSUMED_TAGS.has(_tag)) return;
    CONSUMED_TAGS.set(_tag, getLast(asPresentArray(TRANSACTION_STACK)));
    // We need to mark the tag and all of its subtags as consumed, so we need to
    // cast it and access its internals. In the future this shouldn't be necessary,
    // this is only for computed properties.
    let subtag = _tag.subtag;
    subtag && debug.markTagAsConsumed && (Array.isArray(subtag) ? subtag.forEach(debug.markTagAsConsumed) : debug.markTagAsConsumed(subtag));
  }, debug.assertTagNotConsumed = (tag, obj, keyName) => {
    if (null === CONSUMED_TAGS) return;
    let transaction = CONSUMED_TAGS.get(tag);
    var error;
    //////////
    if (transaction)
      // This hack makes the assertion message nicer, we can cut off the first
      // few lines of the stack trace and let users know where the actual error
      // occurred.
      try {
        assert(!1, makeTrackingErrorMessage(transaction, obj, keyName));
      } catch (e) {
        if ("object" == typeof (error = e) && null !== error && "stack" in error && "string" == typeof error.stack) {
          let updateStackBegin = e.stack.indexOf("Stack trace for the update:");
          if (-1 !== updateStackBegin) {
            let start = nthIndex(e.stack, "\n", 1, updateStackBegin),
              end = nthIndex(e.stack, "\n", 4, updateStackBegin);
            e.stack = e.stack.substr(0, start) + e.stack.substr(end);
          }
        }
        throw e;
      }
  };
}
const CONSTANT = 0,
  INITIAL = 1,
  VOLATILE = NaN;
let $REVISION = 1;
function bump() {
  $REVISION++;
}

//////////
const UPDATABLE_TAG_ID = 1,
  COMPUTE = Symbol("TAG_COMPUTE");

//////////
/**
 * `value` receives a tag and returns an opaque Revision based on that tag. This
 * snapshot can then later be passed to `validate` with the same tag to
 * determine if the tag has changed at all since the time that `value` was
 * called.
 *
 * @param tag
 */
function valueForTag(tag) {
  return tag[COMPUTE]();
}

/**
 * `validate` receives a tag and a snapshot from a previous call to `value` with
 * the same tag, and determines if the tag is still valid compared to the
 * snapshot. If the tag's state has changed at all since then, `validate` will
 * return false, otherwise it will return true. This is used to determine if a
 * calculation related to the tags should be rerun.
 *
 * @param tag
 * @param snapshot
 */
function validateTag(tag, snapshot) {
  return snapshot >= tag[COMPUTE]();
}

//////////
const TYPE = Symbol("TAG_TYPE");

// this is basically a const
let ALLOW_CYCLES;
isDevelopingApp() && (ALLOW_CYCLES = new WeakMap());
class MonomorphicTagImpl {
  static combine(tags) {
    switch (tags.length) {
      case 0:
        return CONSTANT_TAG;
      case 1:
        return tags[0];
      default:
        {
          let tag = new MonomorphicTagImpl(2);
          return tag.subtag = tags, tag;
        }
    }
  }
  revision = 1;
  lastChecked = 1;
  lastValue = 1;
  isUpdating = !1;
  subtag = null;
  subtagBufferCache = null;
  [TYPE];
  constructor(type) {
    this[TYPE] = type;
  }
  [COMPUTE]() {
    let {
      lastChecked: lastChecked
    } = this;
    if (!0 === this.isUpdating) {
      if (isDevelopingApp() && void 0 !== ALLOW_CYCLES && !ALLOW_CYCLES.has(this)) throw new Error("Cycles in tags are not allowed");
      this.lastChecked = ++$REVISION;
    } else if (lastChecked !== $REVISION) {
      this.isUpdating = !0, this.lastChecked = $REVISION;
      try {
        let {
          subtag: subtag,
          revision: revision
        } = this;
        if (null !== subtag) if (Array.isArray(subtag)) for (const tag of subtag) {
          let value = tag[COMPUTE]();
          revision = Math.max(value, revision);
        } else {
          let subtagValue = subtag[COMPUTE]();
          subtagValue === this.subtagBufferCache ? revision = Math.max(revision, this.lastValue) : (
          // Clear the temporary buffer cache
          this.subtagBufferCache = null, revision = Math.max(revision, subtagValue));
        }
        this.lastValue = revision;
      } finally {
        this.isUpdating = !1;
      }
    }
    return this.lastValue;
  }
  static updateTag(_tag, _subtag) {
    if (isDevelopingApp() && _tag[TYPE] !== UPDATABLE_TAG_ID) throw new Error("Attempted to update a tag that was not updatable");
    // TODO: TS 3.7 should allow us to do this via assertion
    let tag = _tag,
      subtag = _subtag;
    subtag === CONSTANT_TAG ? tag.subtag = null : (
    // There are two different possibilities when updating a subtag:
    // 1. subtag[COMPUTE]() <= tag[COMPUTE]();
    // 2. subtag[COMPUTE]() > tag[COMPUTE]();
    // The first possibility is completely fine within our caching model, but
    // the second possibility presents a problem. If the parent tag has
    // already been read, then it's value is cached and will not update to
    // reflect the subtag's greater value. Next time the cache is busted, the
    // subtag's value _will_ be read, and it's value will be _greater_ than
    // the saved snapshot of the parent, causing the resulting calculation to
    // be rerun erroneously.
    // In order to prevent this, when we first update to a new subtag we store
    // its computed value, and then check against that computed value on
    // subsequent updates. If its value hasn't changed, then we return the
    // parent's previous value. Once the subtag changes for the first time,
    // we clear the cache and everything is finally in sync with the parent.
    tag.subtagBufferCache = subtag[COMPUTE](), tag.subtag = subtag);
  }
  static dirtyTag(tag, disableConsumptionAssertion) {
    if (isDevelopingApp() && tag[TYPE] !== UPDATABLE_TAG_ID && 0 !== tag[TYPE]) throw new Error("Attempted to dirty a tag that was not dirtyable");
    isDevelopingApp() && !0 !== disableConsumptionAssertion &&
    // Usually by this point, we've already asserted with better error information,
    // but this is our last line of defense.
    unwrap(debug.assertTagNotConsumed)(tag), tag.revision = ++$REVISION, scheduleRevalidate();
  }
}
const DIRTY_TAG = MonomorphicTagImpl.dirtyTag,
  UPDATE_TAG = MonomorphicTagImpl.updateTag;

//////////
function createTag() {
  return new MonomorphicTagImpl(0);
}
function createUpdatableTag() {
  return new MonomorphicTagImpl(UPDATABLE_TAG_ID);
}

//////////
const CONSTANT_TAG = new MonomorphicTagImpl(3);
function isConstTag(tag) {
  return tag === CONSTANT_TAG;
}

//////////
class VolatileTag {
  [TYPE] = 100;
  [COMPUTE]() {
    return NaN;
  }
}
const VOLATILE_TAG = new VolatileTag();

//////////
class CurrentTag {
  [TYPE] = 101;
  [COMPUTE]() {
    return $REVISION;
  }
}
const CURRENT_TAG = new CurrentTag(),
  combine = MonomorphicTagImpl.combine;

//////////
// Warm
let tag1 = createUpdatableTag(),
  tag2 = createUpdatableTag(),
  tag3 = createUpdatableTag();
valueForTag(tag1), DIRTY_TAG(tag1), valueForTag(tag1), UPDATE_TAG(tag1, combine([tag2, tag3])), valueForTag(tag1), DIRTY_TAG(tag2), valueForTag(tag1), DIRTY_TAG(tag3), valueForTag(tag1), UPDATE_TAG(tag1, tag3), valueForTag(tag1), DIRTY_TAG(tag3), valueForTag(tag1);

///////////
const TRACKED_TAGS = new WeakMap();
function dirtyTagFor(obj, key, meta) {
  if (isDevelopingApp() && ("object" != typeof (u = obj) || null === u) && "function" != typeof u) throw new Error("BUG: Can't update a tag for a primitive");
  var u;
  let tags = void 0 === meta ? TRACKED_TAGS.get(obj) : meta;
  // No tags have been setup for this object yet, return
  if (void 0 === tags) return;
  // Dirty the tag for the specific property if it exists
  let propertyTag = tags.get(key);
  void 0 !== propertyTag && (isDevelopingApp() && unwrap(debug.assertTagNotConsumed)(propertyTag, obj, key), DIRTY_TAG(propertyTag, !0));
}
function tagMetaFor(obj) {
  let tags = TRACKED_TAGS.get(obj);
  return void 0 === tags && (tags = new Map(), TRACKED_TAGS.set(obj, tags)), tags;
}
function tagFor(obj, key, meta) {
  let tags = void 0 === meta ? tagMetaFor(obj) : meta,
    tag = tags.get(key);
  return void 0 === tag && (tag = createUpdatableTag(), tags.set(key, tag)), tag;
}

/**
 * An object that that tracks @tracked properties that were consumed.
 */
class Tracker {
  tags = new Set();
  last = null;
  add(tag) {
    tag !== CONSTANT_TAG && (this.tags.add(tag), isDevelopingApp() && unwrap(debug.markTagAsConsumed)(tag), this.last = tag);
  }
  combine() {
    let {
      tags: tags
    } = this;
    return 0 === tags.size ? CONSTANT_TAG : 1 === tags.size ? this.last : combine(Array.from(this.tags));
  }
}

/**
 * Whenever a tracked computed property is entered, the current tracker is
 * saved off and a new tracker is replaced.
 *
 * Any tracked properties consumed are added to the current tracker.
 *
 * When a tracked computed property is exited, the tracker's tags are
 * combined and added to the parent tracker.
 *
 * The consequence is that each tracked computed property has a tag
 * that corresponds to the tracked properties consumed inside of
 * itself, including child tracked computed properties.
 */
let CURRENT_TRACKER = null;
const OPEN_TRACK_FRAMES = [];
function beginTrackFrame(debuggingContext) {
  OPEN_TRACK_FRAMES.push(CURRENT_TRACKER), CURRENT_TRACKER = new Tracker(), isDevelopingApp() && unwrap(debug.beginTrackingTransaction)(debuggingContext);
}
function endTrackFrame() {
  let current = CURRENT_TRACKER;
  if (isDevelopingApp()) {
    if (0 === OPEN_TRACK_FRAMES.length) throw new Error("attempted to close a tracking frame, but one was not open");
    unwrap(debug.endTrackingTransaction)();
  }
  return CURRENT_TRACKER = OPEN_TRACK_FRAMES.pop() || null, unwrap(current).combine();
}
function beginUntrackFrame() {
  OPEN_TRACK_FRAMES.push(CURRENT_TRACKER), CURRENT_TRACKER = null;
}
function endUntrackFrame() {
  if (isDevelopingApp() && 0 === OPEN_TRACK_FRAMES.length) throw new Error("attempted to close a tracking frame, but one was not open");
  CURRENT_TRACKER = OPEN_TRACK_FRAMES.pop() || null;
}

// This function is only for handling errors and resetting to a valid state
function resetTracking() {
  for (; OPEN_TRACK_FRAMES.length > 0;) OPEN_TRACK_FRAMES.pop();
  if (CURRENT_TRACKER = null, isDevelopingApp()) return unwrap(debug.resetTrackingTransaction)();
}
function isTracking() {
  return null !== CURRENT_TRACKER;
}
function consumeTag(tag) {
  null !== CURRENT_TRACKER && CURRENT_TRACKER.add(tag);
}

// public interface
const FN = Symbol("FN"),
  LAST_VALUE = Symbol("LAST_VALUE"),
  TAG = Symbol("TAG"),
  SNAPSHOT = Symbol("SNAPSHOT"),
  DEBUG_LABEL = Symbol("DEBUG_LABEL");
function createCache(fn, debuggingLabel) {
  if (isDevelopingApp() && "function" != typeof fn) throw new Error(`createCache() must be passed a function as its first parameter. Called with: ${String(fn)}`);
  let cache = {
    [FN]: fn,
    [LAST_VALUE]: void 0,
    [TAG]: void 0,
    [SNAPSHOT]: -1
  };
  return isDevelopingApp() && (cache[DEBUG_LABEL] = debuggingLabel), cache;
}
function getValue(cache) {
  assertCache(cache, "getValue");
  let fn = cache[FN],
    tag = cache[TAG],
    snapshot = cache[SNAPSHOT];
  if (void 0 !== tag && validateTag(tag, snapshot)) consumeTag(tag);else {
    beginTrackFrame();
    try {
      cache[LAST_VALUE] = fn();
    } finally {
      tag = endTrackFrame(), cache[TAG] = tag, cache[SNAPSHOT] = valueForTag(tag), consumeTag(tag);
    }
  }
  return cache[LAST_VALUE];
}
function isConst(cache) {
  assertCache(cache, "isConst");
  let tag = cache[TAG];
  // replace this with `expect` when we can
  return function (tag, cache) {
    if (isDevelopingApp() && void 0 === tag) throw new Error(`isConst() can only be used on a cache once getValue() has been called at least once. Called with cache function:\n\n${String(cache[FN])}`);
  }
  //////////
  // Legacy tracking APIs
  // track() shouldn't be necessary at all in the VM once the autotracking
  // refactors are merged, and we should generally be moving away from it. It may
  // be necessary in Ember for a while longer, but I think we'll be able to drop
  // it in favor of cache sooner rather than later.
  (tag, cache), isConstTag(tag);
}
function assertCache(value, fnName) {
  if (isDevelopingApp() && ("object" != typeof value || null === value || !(FN in value))) throw new Error(`${fnName}() can only be used on an instance of a cache created with createCache(). Called with: ${String(value)}`);
}
function track(block, debugLabel) {
  let tag;
  beginTrackFrame(debugLabel);
  try {
    block();
  } finally {
    tag = endTrackFrame();
  }
  return tag;
}

// untrack() is currently mainly used to handle places that were previously not
// tracked, and that tracking now would cause backtracking rerender assertions.
// I think once we move everyone forward onto modern APIs, we'll probably be
// able to remove it, but I'm not sure yet.
function untrack(callback) {
  beginUntrackFrame();
  try {
    return callback();
  } finally {
    endUntrackFrame();
  }
}
function trackedData(key, initializer) {
  let values = new WeakMap(),
    hasInitializer = "function" == typeof initializer;
  return {
    getter: function (self) {
      let value;
      // If the field has never been initialized, we should initialize it
      return consumeTag(tagFor(self, key)), hasInitializer && !values.has(self) ? (value = initializer.call(self), values.set(self, value)) : value = values.get(self), value;
    },
    setter: function (self, value) {
      dirtyTagFor(self, key), values.set(self, value);
    }
  };
}
const GLIMMER_VALIDATOR_REGISTRATION = Symbol("GLIMMER_VALIDATOR_REGISTRATION"),
  globalObj = function () {
    if ("undefined" != typeof globalThis) return globalThis;
    if ("undefined" != typeof self) return self;
    if ("undefined" != typeof window) return window;
    if ("undefined" != typeof global) return global;
    throw new Error("unable to locate global object");
  }();
if (!0 === globalObj[GLIMMER_VALIDATOR_REGISTRATION]) throw new Error("The `@glimmer/validator` library has been included twice in this application. It could be different versions of the package, or the same version included twice by mistake. `@glimmer/validator` depends on having a single copy of the package in use at any time in an application, even if they are the same version. You must dedupe your build to remove the duplicate packages in order to prevent this error.");
globalObj[GLIMMER_VALIDATOR_REGISTRATION] = !0;

export { ALLOW_CYCLES, COMPUTE, CONSTANT, CONSTANT_TAG, CURRENT_TAG, CurrentTag, INITIAL, VOLATILE, VOLATILE_TAG, VolatileTag, beginTrackFrame, beginUntrackFrame, bump, combine, consumeTag, createCache, createTag, createUpdatableTag, debug, DIRTY_TAG as dirtyTag, dirtyTagFor, endTrackFrame, endUntrackFrame, getValue, isConst, isConstTag, isTracking, resetTracking, tagFor, tagMetaFor, track, trackedData, untrack, UPDATE_TAG as updateTag, validateTag, valueForTag };
