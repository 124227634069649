import { registerAsyncHelper, registerHelper } from './test/helpers.js';
import andThen from './helpers/and_then.js';
import currentPath from './helpers/current_path.js';
import currentRouteName from './helpers/current_route_name.js';
import currentURL from './helpers/current_url.js';
import { pauseTest, resumeTest } from './helpers/pause_test.js';
import visit from './helpers/visit.js';
import wait from './helpers/wait.js';

registerAsyncHelper('visit', visit);
registerAsyncHelper('wait', wait);
registerAsyncHelper('andThen', andThen);
registerAsyncHelper('pauseTest', pauseTest);
registerHelper('currentRouteName', currentRouteName);
registerHelper('currentPath', currentPath);
registerHelper('currentURL', currentURL);
registerHelper('resumeTest', resumeTest);
