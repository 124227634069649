import { template as template_ff8be7491b71453cb437e22119afd2d7 } from "@ember/template-compiler";
import PixBannerAlert from '@1024pix/pix-ui/components/pix-banner-alert';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import ENV from 'junior/config/environment';
import isEmpty from 'lodash/isEmpty';
export default class BannerCommunication extends Component {
    bannerType = ENV.APP.BANNER_TYPE;
    _rawBannerContent = ENV.APP.BANNER_CONTENT;
    get isEnabled() {
        return !isEmpty(this._rawBannerContent) && !isEmpty(this.bannerType);
    }
    get bannerContent() {
        return htmlSafe(this._rawBannerContent);
    }
    static{
        template_ff8be7491b71453cb437e22119afd2d7(`
    {{#if this.isEnabled}}
      <PixBannerAlert @type={{this.bannerType}} class="sticker-banner">
        {{this.bannerContent}}
      </PixBannerAlert>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
