import '../-internals/meta/lib/meta.js';
import '../../shared-chunks/mandatory-setter-BiXq-dpN.js';
import '@embroider/macros';
import '../debug/index.js';
import '../../@glimmer/destroyable/index.js';
import '../../@glimmer/validator/index.js';
import '../../@glimmer/manager/index.js';
import { G as addListener, k as sendEvent, H as removeListener, x as hasListeners } from '../../shared-chunks/cache-Djf2I3Za.js';
export { N as on } from '../../shared-chunks/cache-Djf2I3Za.js';
import '../../shared-chunks/env-CwR5CFCu.js';
import Mixin from './mixin.js';

/**
@module @ember/object/evented
*/

/**
  This mixin allows for Ember objects to subscribe to and emit events.

  ```app/utils/person.js
  import EmberObject from '@ember/object';
  import Evented from '@ember/object/evented';

  export default EmberObject.extend(Evented, {
    greet() {
      // ...
      this.trigger('greet');
    }
  });
  ```

  ```javascript
  var person = Person.create();

  person.on('greet', function() {
    console.log('Our person has greeted');
  });

  person.greet();

  // outputs: 'Our person has greeted'
  ```

  You can also chain multiple event subscriptions:

  ```javascript
  person.on('greet', function() {
    console.log('Our person has greeted');
  }).one('greet', function() {
    console.log('Offer one-time special');
  }).off('event', this, forgetThis);
  ```

  @class Evented
  @public
 */

const Evented = Mixin.create({
  on(name, target, method) {
    addListener(this, name, target, method);
    return this;
  },
  one(name, target, method) {
    addListener(this, name, target, method, true);
    return this;
  },
  trigger(name, ...args) {
    sendEvent(this, name, args);
  },
  off(name, target, method) {
    removeListener(this, name, target, method);
    return this;
  },
  has(name) {
    return hasListeners(this, name);
  }
});

export { Evented as default };
