import '../../../@ember/debug/index.js';
import { isDevelopingApp } from '@embroider/macros';
import { assert } from '../../../@ember/debug/lib/assert.js';

function andThen(app, callback) {
  let wait = app.testHelpers['wait'];
  (isDevelopingApp() && !(wait) && assert('[BUG] Missing wait helper', wait));
  return wait(callback(app));
}

export { andThen as default };
