import { e as isObject } from './mandatory-setter-BiXq-dpN.js';

const NAMES = new WeakMap();
function setName(obj, name) {
  if (isObject(obj)) NAMES.set(obj, name);
}
function getName(obj) {
  return NAMES.get(obj);
}

export { getName as g, setName as s };
