import { template as template_377a7b173c4748e190e13edd92dc92c6 } from "@ember/template-compiler";
import IdentifiedLearner from './identified-learner';
export default template_377a7b173c4748e190e13edd92dc92c6(`
  <div class="header">
    {{yield}}
    <IdentifiedLearner />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
