import '../-internals/meta/lib/meta.js';
import { g as guidFor } from '../../shared-chunks/mandatory-setter-BiXq-dpN.js';
import { isDevelopingApp } from '@embroider/macros';
import { g as getName, s as setName } from '../../shared-chunks/name-Dx2bGFVv.js';
import '../debug/index.js';
import '../../@glimmer/destroyable/index.js';
import '../../@glimmer/validator/index.js';
import '../../@glimmer/manager/index.js';
import { g as get } from '../../shared-chunks/cache-Djf2I3Za.js';
import { N as NAMESPACES, a as NAMESPACES_BY_ID, p as processAllNamespaces, f as findNamespace, b as addNamespace, c as findNamespaces, d as processNamespace, r as removeNamespace } from '../../shared-chunks/namespace_search-Ddzb9tZD.js';
import EmberObject from '../object/index.js';
import { assert } from '../debug/lib/assert.js';

/**
@module @ember/application/namespace
*/

class Namespace extends EmberObject {
  static NAMESPACES = NAMESPACES;
  static NAMESPACES_BY_ID = NAMESPACES_BY_ID;
  static processAll = processAllNamespaces;
  static byName = findNamespace;
  init(properties) {
    super.init(properties);
    addNamespace(this);
  }
  toString() {
    let existing_name = get(this, 'name') || get(this, 'modulePrefix');
    if (existing_name) {
      (isDevelopingApp() && !(typeof existing_name === 'string') && assert("name wasn't a string", typeof existing_name === 'string'));
      return existing_name;
    }
    findNamespaces();
    let name = getName(this);
    if (name === undefined) {
      name = guidFor(this);
      setName(this, name);
    }
    return name;
  }
  nameClasses() {
    processNamespace(this);
  }
  destroy() {
    removeNamespace(this);
    return super.destroy();
  }
}

// Declare on the prototype to have a single shared value.
Namespace.prototype.isNamespace = true;

export { Namespace as default };
