let registerAsyncHelper;
let registerHelper;
let registerWaiter;
let unregisterHelper;
let unregisterWaiter;
let _impl;
let testingNotAvailableMessage = () => {
  throw new Error('Attempted to use test utilities, but `ember-testing` was not included');
};
registerAsyncHelper = testingNotAvailableMessage;
registerHelper = testingNotAvailableMessage;
registerWaiter = testingNotAvailableMessage;
unregisterHelper = testingNotAvailableMessage;
unregisterWaiter = testingNotAvailableMessage;
function registerTestImplementation(impl) {
  let {
    Test
  } = impl;
  registerAsyncHelper = Test.registerAsyncHelper;
  registerHelper = Test.registerHelper;
  registerWaiter = Test.registerWaiter;
  unregisterHelper = Test.unregisterHelper;
  unregisterWaiter = Test.unregisterWaiter;
  _impl = impl;
}

export { _impl, registerAsyncHelper, registerHelper, registerTestImplementation, registerWaiter, unregisterHelper, unregisterWaiter };
