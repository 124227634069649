import '../../../meta/lib/meta.js';
import '../../../../../shared-chunks/mandatory-setter-BiXq-dpN.js';
import '@embroider/macros';
import '../../../../debug/index.js';
import '../../../../../@glimmer/destroyable/index.js';
import '../../../../../@glimmer/validator/index.js';
import '../../../../../@glimmer/manager/index.js';
import { B as nativeDescDecorator } from '../../../../../shared-chunks/cache-Djf2I3Za.js';
import '../../../../../shared-chunks/env-CwR5CFCu.js';
import Mixin from '../../../../object/mixin.js';
import { addChildView, getChildViews } from '../system/utils.js';

/**
@module ember
*/

const ChildViewsSupport = Mixin.create({
  /**
    Array of child views. You should never edit this array directly.
     @property childViews
    @type Array
    @default []
    @private
  */
  childViews: nativeDescDecorator({
    configurable: false,
    enumerable: false,
    get() {
      return getChildViews(this);
    }
  }),
  appendChild(view) {
    addChildView(this, view);
  }
});

export { ChildViewsSupport as default };
