import { p as privatize } from './registry-B8WARvkP.js';
import { getOwner } from '../@ember/-internals/owner/index.js';
import '../@ember/debug/index.js';
import { R as RootTemplate, b as Renderer, O as OutletView, c as OutletTemplate, I as Input, L as LinkTo, T as Textarea } from './index-CQygUgr9.js';
import { clientBuilder, rehydrationBuilder } from '../@glimmer/runtime/index.js';
import { serializeBuilder } from '../@glimmer/node/index.js';
import { isDevelopingApp } from '@embroider/macros';
import { assert } from '../@ember/debug/lib/assert.js';

function setupApplicationRegistry(registry) {
  // because we are using injections we can't use instantiate false
  // we need to use bind() to copy the function so factory for
  // association won't leak
  registry.register('service:-dom-builder', {
    // Additionally, we *must* constrain this to require `props` on create, else
    // we *know* it cannot have an owner.
    create(props) {
      let owner = getOwner(props);
      (isDevelopingApp() && !(owner) && assert('DomBuilderService is unexpectedly missing an owner', owner));
      let env = owner.lookup('-environment:main');
      switch (env._renderMode) {
        case 'serialize':
          return serializeBuilder.bind(null);
        case 'rehydrate':
          return rehydrationBuilder.bind(null);
        default:
          return clientBuilder.bind(null);
      }
    }
  });
  registry.register(privatize`template:-root`, RootTemplate);
  registry.register('renderer:-dom', Renderer);
}
function setupEngineRegistry(registry) {
  registry.optionsForType('template', {
    instantiate: false
  });
  registry.register('view:-outlet', OutletView);
  registry.register('template:-outlet', OutletTemplate);
  registry.optionsForType('helper', {
    instantiate: false
  });
  registry.register('component:input', Input);
  registry.register('component:link-to', LinkTo);
  registry.register('component:textarea', Textarea);
}

export { setupApplicationRegistry as a, setupEngineRegistry as s };
