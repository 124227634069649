import { G as GUID_KEY, i as intern } from './mandatory-setter-BiXq-dpN.js';
import { isDevelopingApp } from '@embroider/macros';

const GENERATED_SYMBOLS = [];
function isInternalSymbol(possibleSymbol) {
  return GENERATED_SYMBOLS.indexOf(possibleSymbol) !== -1;
}

// Some legacy symbols still need to be enumerable for a variety of reasons.
// This code exists for that, and as a fallback in IE11. In general, prefer
// `symbol` below when creating a new symbol.
function enumerableSymbol(debugName) {
  // TODO: Investigate using platform symbols, but we do not
  // want to require non-enumerability for this API, which
  // would introduce a large cost.
  let id = GUID_KEY + Math.floor(Math.random() * Date.now()).toString();
  let symbol = intern(`__${debugName}${id}__`);
  if (isDevelopingApp()) {
    GENERATED_SYMBOLS.push(symbol);
  }
  return symbol;
}
const symbol = Symbol;

const objectToString = Object.prototype.toString;
function isNone(obj) {
  return obj === null || obj === undefined;
}

/*
 A `toString` util function that supports objects without a `toString`
 method, e.g. an object created with `Object.create(null)`.
*/
function toString(obj) {
  if (typeof obj === 'string') {
    return obj;
  }
  if (null === obj) return 'null';
  if (undefined === obj) return 'undefined';
  if (Array.isArray(obj)) {
    // Reimplement Array.prototype.join according to spec (22.1.3.13)
    // Changing ToString(element) with this safe version of ToString.
    let r = '';
    for (let k = 0; k < obj.length; k++) {
      if (k > 0) {
        r += ',';
      }
      if (!isNone(obj[k])) {
        r += toString(obj[k]);
      }
    }
    return r;
  }
  if (typeof obj.toString === 'function') {
    return obj.toString();
  }
  return objectToString.call(obj);
}

export { enumerableSymbol as e, isInternalSymbol as i, symbol as s, toString as t };
