import '../../@ember/debug/index.js';
import { setAdapter, getAdapter } from './test/adapter.js';
import Adapter from './adapters/adapter.js';
import QUnitAdapter from './adapters/qunit.js';
import { setTesting } from '../../@ember/debug/lib/testing.js';

/* global self */


/**
  Sets Ember up for testing. This is useful to perform
  basic setup steps in order to unit test.

  Use `App.setupForTesting` to perform integration tests (full
  application testing).

  @method setupForTesting
  @namespace Ember
  @since 1.5.0
  @private
*/
function setupForTesting() {
  setTesting(true);
  let adapter = getAdapter();
  // if adapter is not manually set default to QUnit
  if (!adapter) {
    setAdapter(typeof self.QUnit === 'undefined' ? Adapter.create() : QUnitAdapter.create());
  }
}

export { setupForTesting as default };
