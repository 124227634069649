import '../../shared-chunks/mandatory-setter-BiXq-dpN.js';
import '@embroider/macros';
import '../../shared-chunks/env-CwR5CFCu.js';
import '../../@ember/debug/index.js';
import '../../@ember/runloop/index.js';
import '../../@ember/-internals/meta/lib/meta.js';
import '../../@glimmer/destroyable/index.js';
import '../../@glimmer/validator/index.js';
import '../../@glimmer/manager/index.js';
import '../../shared-chunks/cache-Djf2I3Za.js';
import { onLoad } from '../../@ember/application/lib/lazy_load.js';
import '../../@ember/-internals/runtime/lib/mixins/registry_proxy.js';
import '../../@ember/-internals/runtime/lib/mixins/container_proxy.js';
import '../../@ember/-internals/runtime/lib/mixins/comparable.js';
import '../../@ember/-internals/runtime/lib/mixins/action_handler.js';
import '../../@ember/-internals/runtime/lib/mixins/-proxy.js';
import '../../@ember/enumerable/mutable.js';
import '../../@ember/-internals/runtime/lib/mixins/target_action_support.js';
import '../../@ember/-internals/runtime/lib/ext/rsvp.js';
import '../../@ember/object/index.js';
import '../../@ember/-internals/views/lib/system/action_manager.js';
import '../../@ember/-internals/views/lib/component_lookup.js';
import '../../@ember/-internals/views/lib/views/core_view.js';
import '../../@ember/-internals/views/lib/mixins/class_names_support.js';
import '../../@ember/-internals/views/lib/mixins/child_views_support.js';
import '../../@ember/-internals/views/lib/mixins/view_state_support.js';
import '../../@ember/-internals/views/lib/mixins/view_support.js';
import '../../@ember/-internals/views/lib/mixins/action_support.js';
import '../../@ember/routing/route.js';
import '../../@ember/routing/router.js';
import '../../@ember/routing/none-location.js';
import '../../@ember/engine/instance.js';
import '../../route-recognizer/index.js';
import '../../shared-chunks/rsvp-DaQAFb0W.js';
import '../../shared-chunks/unrecognized-url-error-zpz-JEoG.js';
import '../../@ember/routing/lib/routing-service.js';
import '../../@ember/controller/index.js';
import '../../@glimmer/opcode-compiler/index.js';
import '../../shared-chunks/index-CQygUgr9.js';
import '../../shared-chunks/registry-B8WARvkP.js';
import '../../@glimmer/runtime/index.js';
import '../../@glimmer/reference/index.js';
import '../../@ember/engine/index.js';
import '../../@ember/routing/router-service.js';

let name = 'deferReadiness in `testing` mode';
onLoad('Ember.Application', function (ApplicationClass) {
  if (!ApplicationClass.initializers[name]) {
    ApplicationClass.initializer({
      name: name,
      initialize(application) {
        if (application.testing) {
          application.deferReadiness();
        }
      }
    });
  }
});
