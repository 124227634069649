let requests = [];
function pendingRequests() {
  return requests.length;
}
function clearPendingRequests() {
  requests.length = 0;
}
function incrementPendingRequests(_, xhr) {
  requests.push(xhr);
}
function decrementPendingRequests(_, xhr) {
  setTimeout(function () {
    for (let i = 0; i < requests.length; i++) {
      if (xhr === requests[i]) {
        requests.splice(i, 1);
        break;
      }
    }
  }, 0);
}

export { clearPendingRequests, decrementPendingRequests, incrementPendingRequests, pendingRequests };
