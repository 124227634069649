import { g as get, T as changeProperties } from './cache-Djf2I3Za.js';
import { s as set } from './property_set-DS4X3Soy.js';

/**
 @module @ember/object
*/

/**
  To get multiple properties at once, call `getProperties`
  with an object followed by a list of strings or an array:

  ```javascript
  import { getProperties } from '@ember/object';

  getProperties(record, 'firstName', 'lastName', 'zipCode');
  // { firstName: 'John', lastName: 'Doe', zipCode: '10011' }
  ```

  is equivalent to:

  ```javascript
  import { getProperties } from '@ember/object';

  getProperties(record, ['firstName', 'lastName', 'zipCode']);
  // { firstName: 'John', lastName: 'Doe', zipCode: '10011' }
  ```

  @method getProperties
  @static
  @for @ember/object
  @param {Object} obj
  @param {String...|Array} list of keys to get
  @return {Object}
  @public
*/

function getProperties(obj, keys) {
  let ret = {};
  let propertyNames;
  let i = 1;
  if (arguments.length === 2 && Array.isArray(keys)) {
    i = 0;
    propertyNames = arguments[1];
  } else {
    propertyNames = Array.from(arguments);
  }
  for (; i < propertyNames.length; i++) {
    // SAFETY: we are just walking the list of property names, so we know the
    // index access never produces `undefined`.
    let name = propertyNames[i];
    ret[name] = get(obj, name);
  }
  return ret;
}

/**
 @module @ember/object
*/
/**
  Set a list of properties on an object. These properties are set inside
  a single `beginPropertyChanges` and `endPropertyChanges` batch, so
  observers will be buffered.

  ```javascript
  import EmberObject from '@ember/object';
  let anObject = EmberObject.create();

  anObject.setProperties({
    firstName: 'Stanley',
    lastName: 'Stuart',
    age: 21
  });
  ```

  @method setProperties
  @static
  @for @ember/object
  @param obj
  @param {Object} properties
  @return properties
  @public
*/

function setProperties(obj, properties) {
  if (properties === null || typeof properties !== 'object') {
    return properties;
  }
  changeProperties(() => {
    let props = Object.keys(properties);
    for (let propertyName of props) {
      // SAFETY: casting `properties` this way is safe because any object in JS
      // can be indexed this way, and the result will be `unknown`, making it
      // safe for callers.
      set(obj, propertyName, properties[propertyName]);
    }
  });
  return properties;
}

export { getProperties as g, setProperties as s };
