import '../../../@ember/debug/index.js';
import EmberRouter from '../../../@ember/routing/router.js';
import { run } from '../../../@ember/runloop/index.js';
import { isDevelopingApp } from '@embroider/macros';
import { assert } from '../../../@ember/debug/lib/assert.js';

/**
  Loads a route, sets up any controllers, and renders any templates associated
  with the route as though a real user had triggered the route change while
  using your app.

  Example:

  ```javascript
  visit('posts/index').then(function() {
    // assert something
  });
  ```

  @method visit
  @param {String} url the name of the route
  @return {RSVP.Promise<undefined>}
  @public
*/
function visit(app, url) {
  (isDevelopingApp() && !(app.__container__) && assert('[BUG] Missing container', app.__container__));
  const router = app.__container__.lookup('router:main');
  (isDevelopingApp() && !(router instanceof EmberRouter) && assert('[BUG] router:main is not a Router', router instanceof EmberRouter));
  let shouldHandleURL = false;
  app.boot().then(() => {
    (isDevelopingApp() && !(typeof router.location !== 'string') && assert('[BUG] router.location is still a string', typeof router.location !== 'string'));
    router.location.setURL(url);
    if (shouldHandleURL) {
      (isDevelopingApp() && !(app.__deprecatedInstance__) && assert("[BUG] __deprecatedInstance__ isn't set", app.__deprecatedInstance__));
      run(app.__deprecatedInstance__, 'handleURL', url);
    }
  });
  if (app._readinessDeferrals > 0) {
    // SAFETY: This should be safe, though it is odd.
    router.initialURL = url;
    run(app, 'advanceReadiness');
    delete router.initialURL;
  } else {
    shouldHandleURL = true;
  }
  let wait = app.testHelpers['wait'];
  (isDevelopingApp() && !(wait) && assert('[BUG] missing wait helper', wait));
  return wait();
}

export { visit as default };
