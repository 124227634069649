import { d as i, r as l, c as n, o as a, n as p, a as t, b as c, F as u, e as f, f as m, _ as g, g as _ } from "../_plugin-vue_export-helper-CG_vnhyz.js";
const v = { class: "card-inner" }, y = /* @__PURE__ */ i({
  __name: "CarteARetourner",
  props: {
    card: {}
  },
  setup(d) {
    const e = l(!1);
    return (s, r) => (a(), n("div", {
      class: p(["card", { flipped: e.value }]),
      onClick: r[0] || (r[0] = (o) => e.value = !e.value)
    }, [
      t("div", v, [
        t("div", {
          class: "card-face card-front",
          style: c({ backgroundImage: `url('${s.card.frontImageUrl}')` })
        }, null, 4),
        t("div", {
          class: "card-face card-back",
          style: c({ backgroundImage: `url('${s.card.backImageUrl}')` })
        }, null, 4)
      ])
    ], 2));
  }
}), b = /* @__PURE__ */ i({
  __name: "CartesARetourner.ce",
  props: {
    props: { type: Object }
  },
  setup(d) {
    return (e, s) => (a(), n("div", {
      class: "container",
      style: c({ "--cards-per-line": e.props.cardsPerLine })
    }, [
      (a(!0), n(u, null, f(e.props.cards, (r, o) => (a(), m(y, {
        key: o,
        card: r
      }, null, 8, ["card"]))), 128))
    ], 4));
  }
}), k = ".container{display:grid;grid-template-columns:repeat(var(--cards-per-line),1fr);gap:20px;justify-items:center}.card{width:150px;height:150px;perspective:1000px;cursor:pointer}.card-inner{width:100%;height:100%;position:relative;transform-style:preserve-3d;transition:transform .6s}.card.flipped .card-inner{transform:rotateY(180deg)}.card-face{width:100%;height:100%;position:absolute;backface-visibility:hidden;display:flex;justify-content:center;align-items:center;font-size:24px;border-radius:10px;background-size:cover}.card-back{transform:rotateY(180deg)}", h = /* @__PURE__ */ g(b, [["styles", [k]]]);
window.customElements.define("cartes-a-retourner", _(h));
