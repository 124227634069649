import '../../../@ember/-internals/runtime/lib/mixins/registry_proxy.js';
import '../../../@ember/-internals/runtime/lib/mixins/container_proxy.js';
import '../../../@ember/-internals/runtime/lib/mixins/comparable.js';
import '../../../@ember/-internals/runtime/lib/mixins/action_handler.js';
import '../../../@ember/-internals/runtime/lib/mixins/-proxy.js';
import '../../../@ember/enumerable/mutable.js';
import '../../../@ember/-internals/runtime/lib/mixins/target_action_support.js';
import '../../../@ember/-internals/runtime/lib/ext/rsvp.js';
import { _backburner } from '../../../@ember/runloop/index.js';
import { R as RSVP } from '../../../shared-chunks/rsvp-DaQAFb0W.js';

RSVP.configure('async', function (callback, promise) {
  // if schedule will cause autorun, we need to inform adapter
  _backburner.schedule('actions', () => callback(promise));
});

export { RSVP as default };
