import { template as template_7367a745e28b4de697379014820afef0 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import WidthLimitedContent from '../width-limited-content';
export default class ChallengeLayout extends Component {
    get className() {
        return this.args.color ?? 'default';
    }
    static{
        template_7367a745e28b4de697379014820afef0(`
    <div class="challenge-layout challenge-layout--{{this.className}}">
      <WidthLimitedContent>{{yield}}</WidthLimitedContent>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
