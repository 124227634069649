import '../-internals/meta/lib/meta.js';
import { s as symbol } from '../../shared-chunks/to-string-B1BmwUkt.js';
export { g as guidFor } from '../../shared-chunks/mandatory-setter-BiXq-dpN.js';
import '../debug/index.js';
import '../../@glimmer/destroyable/index.js';
import '../../@glimmer/validator/index.js';
import '../../@glimmer/manager/index.js';
import { isDevelopingApp } from '@embroider/macros';
import { G as addListener } from '../../shared-chunks/cache-Djf2I3Za.js';
export { g as cacheFor } from '../../shared-chunks/computed_cache-DmYKevAP.js';
import '../../shared-chunks/env-CwR5CFCu.js';
import EmberObject from './index.js';
import { assert } from '../debug/lib/assert.js';

let FrameworkObject = class FrameworkObject extends EmberObject {};
if (isDevelopingApp()) {
  const INIT_WAS_CALLED = Symbol('INIT_WAS_CALLED');
  let ASSERT_INIT_WAS_CALLED = symbol('ASSERT_INIT_WAS_CALLED');
  FrameworkObject = class DebugFrameworkObject extends EmberObject {
    [INIT_WAS_CALLED] = false;
    init(properties) {
      super.init(properties);
      this[INIT_WAS_CALLED] = true;
    }
    [ASSERT_INIT_WAS_CALLED]() {
      (isDevelopingApp() && !(this[INIT_WAS_CALLED]) && assert(`You must call \`super.init(...arguments);\` or \`this._super(...arguments)\` when overriding \`init\` on a framework object. Please update ${this} to call \`super.init(...arguments);\` from \`init\` when using native classes or \`this._super(...arguments)\` when using \`EmberObject.extend()\`.`, this[INIT_WAS_CALLED]));
    }
  };
  addListener(FrameworkObject.prototype, 'init', null, ASSERT_INIT_WAS_CALLED);
}

export { FrameworkObject };
