import { FrameworkObject } from './-internals.js';
import '../-internals/runtime/lib/mixins/registry_proxy.js';
import '../-internals/runtime/lib/mixins/container_proxy.js';
import '../-internals/runtime/lib/mixins/comparable.js';
import '../-internals/runtime/lib/mixins/action_handler.js';
import ProxyMixin from '../-internals/runtime/lib/mixins/-proxy.js';
import '../enumerable/mutable.js';
import '../-internals/runtime/lib/mixins/target_action_support.js';
import '../-internals/runtime/lib/ext/rsvp.js';

/**
@module @ember/object/proxy
*/


/**
  `ObjectProxy` forwards all properties not defined by the proxy itself
  to a proxied `content` object.

  ```javascript
  import EmberObject from '@ember/object';
  import ObjectProxy from '@ember/object/proxy';

  let exampleObject = EmberObject.create({
    name: 'Foo'
  });

  let exampleProxy = ObjectProxy.create({
    content: exampleObject
  });

  // Access and change existing properties
  exampleProxy.get('name');          // 'Foo'
  exampleProxy.set('name', 'Bar');
  exampleObject.get('name');         // 'Bar'

  // Create new 'description' property on `exampleObject`
  exampleProxy.set('description', 'Foo is a whizboo baz');
  exampleObject.get('description');  // 'Foo is a whizboo baz'
  ```

  While `content` is unset, setting a property to be delegated will throw an
  Error.

  ```javascript
  import ObjectProxy from '@ember/object/proxy';

  let exampleProxy = ObjectProxy.create({
    content: null,
    flag: null
  });
  exampleProxy.set('flag', true);
  exampleProxy.get('flag');         // true
  exampleProxy.get('foo');          // undefined
  exampleProxy.set('foo', 'data');  // throws Error
  ```

  Delegated properties can be bound to and will change when content is updated.

  Computed properties on the proxy itself can depend on delegated properties.

  ```javascript
  import { computed } from '@ember/object';
  import ObjectProxy from '@ember/object/proxy';

  ProxyWithComputedProperty = ObjectProxy.extend({
    fullName: computed('firstName', 'lastName', function() {
      var firstName = this.get('firstName'),
          lastName = this.get('lastName');
      if (firstName && lastName) {
        return firstName + ' ' + lastName;
      }
      return firstName || lastName;
    })
  });

  let exampleProxy = ProxyWithComputedProperty.create();

  exampleProxy.get('fullName');  // undefined
  exampleProxy.set('content', {
    firstName: 'Tom', lastName: 'Dale'
  }); // triggers property change for fullName on proxy

  exampleProxy.get('fullName');  // 'Tom Dale'
  ```

  @class ObjectProxy
  @extends EmberObject
  @uses Ember.ProxyMixin
  @public
*/

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ObjectProxy extends FrameworkObject {}
ObjectProxy.PrototypeMixin.reopen(ProxyMixin);

export { ObjectProxy as default };
