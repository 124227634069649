import { setDispatchOverride } from '../../../@ember/-internals/error-handling/index.js';

let adapter;
function getAdapter() {
  return adapter;
}
function setAdapter(value) {
  adapter = value;
  if (value && typeof value.exception === 'function') {
    setDispatchOverride(adapterDispatch);
  } else {
    setDispatchOverride(null);
  }
}
function asyncStart() {
  if (adapter) {
    adapter.asyncStart();
  }
}
function asyncEnd() {
  if (adapter) {
    adapter.asyncEnd();
  }
}
function adapterDispatch(error) {
  adapter.exception(error);

  // @ts-expect-error Normally unreachable
  console.error(error.stack); // eslint-disable-line no-console
}

export { asyncEnd, asyncStart, getAdapter, setAdapter };
