import { b as getENV, a as getLookup, s as setLookup } from '../shared-chunks/env-CwR5CFCu.js';
import { G as GUID_KEY, b as generateGuid, g as guidFor, u as uuid, w as wrap } from '../shared-chunks/mandatory-setter-BiXq-dpN.js';
import { isDevelopingApp } from '@embroider/macros';
import { c as canInvoke } from '../shared-chunks/invoke-BjRgvK2V.js';
import { C as Cache } from '../shared-chunks/cache-qDyqAcpg.js';
import { C as Container, R as Registry } from '../shared-chunks/registry-B8WARvkP.js';
import { instrument, subscribe, unsubscribe, reset } from '../@ember/instrumentation/index.js';
import { meta } from '../@ember/-internals/meta/lib/meta.js';
import { B as nativeDescDecorator, _ as _getPath, s as setClassicDecorator, S as tracked, m as beginPropertyChanges, T as changeProperties, l as endPropertyChanges, x as hasListeners, O as LIBRARIES, U as isComputed, c as computed, d as defineProperty, g as get, n as notifyPropertyChange, C as ComputedProperty, e as expandProperties, N as on, G as addListener, H as removeListener, k as sendEvent, v as addObserver, w as removeObserver } from '../shared-chunks/cache-Djf2I3Za.js';
import { g as getCachedValueFor } from '../shared-chunks/computed_cache-DmYKevAP.js';
import { warn, debug, deprecate, deprecateFunc, runInDebug } from '../@ember/debug/index.js';
import { createCache, getValue, isConst } from '../@glimmer/validator/index.js';
import { assertDestroyablesDestroyed, associateDestroyableChild, enableDestroyableTracking, isDestroying, isDestroyed, destroy } from '../@glimmer/destroyable/index.js';
import { componentCapabilities, modifierCapabilities, getComponentTemplate, helperCapabilities, setComponentTemplate, setHelperManager, setModifierManager } from '../@glimmer/manager/index.js';
import { s as set, t as trySet } from '../shared-chunks/property_set-DS4X3Soy.js';
import { g as getProperties, s as setProperties } from '../shared-chunks/set_properties-CeGJ0G9T.js';
import { i as isSearchDisabled, e as setSearchDisabled } from '../shared-chunks/namespace_search-Ddzb9tZD.js';
import { isEnabled, FEATURES } from '../@ember/canary-features/index.js';
import Backburner from '../backburner.js/index.js';
import Controller, { ControllerMixin, inject } from '../@ember/controller/index.js';
import Service, { service } from '../@ember/service/index.js';
import EmberObject, { action, observer } from '../@ember/object/index.js';
import '../@ember/object/-internals.js';
import { dependentKeyCompat } from '../@ember/object/compat.js';
import isNone from '../@ember/utils/lib/is_none.js';
import isBlank from '../@ember/utils/lib/is_blank.js';
import isEmpty from '../@ember/utils/lib/is_empty.js';
import isPresent from '../@ember/utils/lib/is_present.js';
import isEqual from '../@ember/utils/lib/is-equal.js';
import typeOf from '../@ember/utils/lib/type-of.js';
import compare from '../@ember/utils/lib/compare.js';
import EmberArray, { A, NativeArray, isArray, MutableArray } from '../@ember/array/index.js';
import RegistryProxyMixin from '../@ember/-internals/runtime/lib/mixins/registry_proxy.js';
import ContainerProxyMixin from '../@ember/-internals/runtime/lib/mixins/container_proxy.js';
import Comparable from '../@ember/-internals/runtime/lib/mixins/comparable.js';
import ActionHandler from '../@ember/-internals/runtime/lib/mixins/action_handler.js';
import ProxyMixin from '../@ember/-internals/runtime/lib/mixins/-proxy.js';
import MutableEnumerable from '../@ember/enumerable/mutable.js';
import '../@ember/-internals/runtime/lib/mixins/target_action_support.js';
import '../@ember/-internals/runtime/lib/ext/rsvp.js';
import { templateFactory } from '../@glimmer/opcode-compiler/index.js';
import { I as Input, C as Component, H as Helper, e as escapeExpression } from '../shared-chunks/index-CQygUgr9.js';
import { a as getTemplates, b as setTemplates } from '../shared-chunks/template_registry-DigcUg9m.js';
import { isSerializationFirstNode, templateOnlyComponent, invokeHelper, hash, array, concat, get as get$1, on as on$1, fn } from '../@glimmer/runtime/index.js';
import { run } from '../@ember/runloop/index.js';
import '../@glimmer/reference/index.js';
import { s as setComponentManager } from '../shared-chunks/managers-CbMgnSoo.js';
import Version from './version.js';
import { getChildViews, getElementView, getRootViews, getViewBounds, getViewBoundingClientRect, getViewClientRects, getViewElement, isSimpleClick } from '../@ember/-internals/views/lib/system/utils.js';
import EventDispatcher from '../@ember/-internals/views/lib/system/event_dispatcher.js';
import ComponentLookup from '../@ember/-internals/views/lib/component_lookup.js';
import '../@ember/-internals/views/lib/views/core_view.js';
import '../@ember/-internals/views/lib/mixins/class_names_support.js';
import '../@ember/-internals/views/lib/mixins/child_views_support.js';
import '../@ember/-internals/views/lib/mixins/view_state_support.js';
import '../@ember/-internals/views/lib/mixins/view_support.js';
import '../@ember/-internals/views/lib/mixins/action_support.js';
import '../@ember/-internals/views/lib/system/action_manager.js';
import ContainerDebugAdapter from '../@ember/debug/container-debug-adapter.js';
import DataAdapter from '../@ember/debug/data-adapter.js';
import { getOnerror, setOnerror } from '../@ember/-internals/error-handling/index.js';
import ArrayProxy from '../@ember/array/proxy.js';
import Application, { getOwner, setOwner } from '../@ember/application/index.js';
import ApplicationInstance from '../@ember/application/instance.js';
import Namespace from '../@ember/application/namespace.js';
import Engine from '../@ember/engine/index.js';
import EngineInstance from '../@ember/engine/instance.js';
import Enumerable from '../@ember/enumerable/index.js';
import CoreObject from '../@ember/object/core.js';
import Evented from '../@ember/object/evented.js';
import Mixin, { mixin } from '../@ember/object/mixin.js';
import Observable from '../@ember/object/observable.js';
import ObjectProxy from '../@ember/object/proxy.js';
import PromiseProxyMixin from '../@ember/object/promise-proxy-mixin.js';
import HashLocation from '../@ember/routing/hash-location.js';
import HistoryLocation from '../@ember/routing/history-location.js';
import NoneLocation from '../@ember/routing/none-location.js';
import Route from '../@ember/routing/route.js';
import EmberRouter from '../@ember/routing/router.js';
import '../route-recognizer/index.js';
import { R as RSVP } from '../shared-chunks/rsvp-DaQAFb0W.js';
import '../shared-chunks/unrecognized-url-error-zpz-JEoG.js';
import '../@ember/routing/lib/routing-service.js';
import generateController, { generateControllerFactory } from '../@ember/routing/lib/generate_controller.js';
import DSLImpl from '../@ember/routing/lib/dsl.js';
import controllerFor from '../@ember/routing/lib/controller_for.js';
import { registerDestructor, unregisterDestructor } from '../@ember/destroyable/index.js';
import { _impl } from '../@ember/test/index.js';
import { __emberTemplateCompiler, compileTemplate } from '../@ember/template-compilation/index.js';
import { onLoad, runLoadHooks } from '../@ember/application/lib/lazy_load.js';
import makeArray from '../@ember/array/lib/make-array.js';
import captureRenderTree from '../@ember/debug/lib/capture-render-tree.js';
import { assert } from '../@ember/debug/lib/assert.js';
import inspect from '../@ember/debug/lib/inspect.js';
import { registerHandler } from '../@ember/debug/lib/deprecate.js';
import { registerHandler as registerHandler$1 } from '../@ember/debug/lib/warn.js';
import { isTesting, setTesting } from '../@ember/debug/lib/testing.js';

/**
@module ember
*/

let Ember;
(function (_Ember) {
  _Ember.isNamespace = true;
  function toString() {
    return 'Ember';
  }
  _Ember.toString = toString;
  _Ember.Container = Container;
  _Ember.Registry = Registry;
  // ****@ember/-internals/glimmer****
  // Partially re-exported from @glimmer/manager
  _Ember._setComponentManager = setComponentManager;
  _Ember._componentManagerCapabilities = componentCapabilities;
  _Ember._modifierManagerCapabilities = modifierCapabilities;
  _Ember.meta = meta;
  _Ember._createCache = createCache;
  _Ember._cacheGetValue = getValue;
  _Ember._cacheIsConst = isConst;
  _Ember._descriptor = nativeDescDecorator;
  _Ember._getPath = _getPath;
  _Ember._setClassicDecorator = setClassicDecorator;
  _Ember._tracked = tracked;
  _Ember.beginPropertyChanges = beginPropertyChanges;
  _Ember.changeProperties = changeProperties;
  _Ember.endPropertyChanges = endPropertyChanges;
  _Ember.hasListeners = hasListeners;
  _Ember.libraries = LIBRARIES;
  _Ember._ContainerProxyMixin = ContainerProxyMixin;
  _Ember._ProxyMixin = ProxyMixin;
  _Ember._RegistryProxyMixin = RegistryProxyMixin;
  _Ember.ActionHandler = ActionHandler;
  _Ember.Comparable = Comparable;
  // ****@ember/-internals/view****
  _Ember.ComponentLookup = ComponentLookup;
  _Ember.EventDispatcher = EventDispatcher;
  _Ember._Cache = Cache;
  _Ember.GUID_KEY = GUID_KEY;
  _Ember.canInvoke = canInvoke;
  _Ember.generateGuid = generateGuid;
  _Ember.guidFor = guidFor;
  _Ember.uuid = uuid;
  _Ember.wrap = wrap;
  _Ember.getOwner = getOwner;
  _Ember.onLoad = onLoad;
  _Ember.runLoadHooks = runLoadHooks;
  _Ember.setOwner = setOwner;
  _Ember.Application = Application;
  // ****@ember/application/instance****
  _Ember.ApplicationInstance = ApplicationInstance;
  // // ****@ember/application/namespace****
  _Ember.Namespace = Namespace;
  // ****@ember/array****
  _Ember.A = A;
  _Ember.Array = EmberArray;
  _Ember.NativeArray = NativeArray;
  _Ember.isArray = isArray;
  _Ember.makeArray = makeArray;
  _Ember.MutableArray = MutableArray;
  // ****@ember/array/proxy****
  _Ember.ArrayProxy = ArrayProxy;
  // ****@ember/canary-features****
  _Ember.FEATURES = {
    isEnabled,
    ...FEATURES
  };
  _Ember._Input = Input;
  _Ember.Component = Component;
  // // ****@ember/component/helper****
  _Ember.Helper = Helper;
  // ****@ember/controller****
  _Ember.Controller = Controller;
  _Ember.ControllerMixin = ControllerMixin;
  // ****@ember/debug****
  _Ember._captureRenderTree = captureRenderTree;
  _Ember.assert = assert;
  _Ember.warn = warn;
  _Ember.debug = debug;
  _Ember.deprecate = deprecate;
  _Ember.deprecateFunc = deprecateFunc;
  _Ember.runInDebug = runInDebug;
  _Ember.inspect = inspect;
  _Ember.Debug = {
    registerDeprecationHandler: registerHandler,
    registerWarnHandler: registerHandler$1,
    // ****@ember/-internals/metal****
    isComputed: isComputed
  };
  _Ember.ContainerDebugAdapter = ContainerDebugAdapter;
  // ****@ember/debug/data-adapter****
  _Ember.DataAdapter = DataAdapter;
  // ****@ember/destroyable****
  _Ember._assertDestroyablesDestroyed = assertDestroyablesDestroyed;
  _Ember._associateDestroyableChild = associateDestroyableChild;
  _Ember._enableDestroyableTracking = enableDestroyableTracking;
  _Ember._isDestroying = isDestroying;
  _Ember._isDestroyed = isDestroyed;
  _Ember._registerDestructor = registerDestructor;
  _Ember._unregisterDestructor = unregisterDestructor;
  _Ember.destroy = destroy;
  _Ember.Engine = Engine;
  // ****@ember/engine/instance****
  _Ember.EngineInstance = EngineInstance;
  // ****@ember/enumerable****
  _Ember.Enumerable = Enumerable;
  // ****@ember/enumerable/mutable****
  _Ember.MutableEnumerable = MutableEnumerable;
  // ****@ember/instrumentation****
  /** @private */
  _Ember.instrument = instrument;
  _Ember.subscribe = subscribe;
  _Ember.Instrumentation = {
    instrument: instrument,
    subscribe: subscribe,
    unsubscribe: unsubscribe,
    reset: reset
  };
  _Ember.Object = EmberObject;
  _Ember._action = action;
  _Ember.computed = computed;
  _Ember.defineProperty = defineProperty;
  _Ember.get = get;
  _Ember.getProperties = getProperties;
  _Ember.notifyPropertyChange = notifyPropertyChange;
  _Ember.observer = observer;
  _Ember.set = set;
  _Ember.trySet = trySet;
  _Ember.setProperties = setProperties;
  _Ember.cacheFor = getCachedValueFor;
  _Ember._dependentKeyCompat = dependentKeyCompat;
  _Ember.ComputedProperty = ComputedProperty;
  _Ember.expandProperties = expandProperties;
  _Ember.CoreObject = CoreObject;
  // ****@ember/object/evented****
  _Ember.Evented = Evented;
  _Ember.on = on;
  _Ember.addListener = addListener;
  _Ember.removeListener = removeListener;
  _Ember.sendEvent = sendEvent;
  _Ember.Mixin = Mixin;
  _Ember.mixin = mixin;
  _Ember.Observable = Observable;
  // ****@ember/object/observers****
  _Ember.addObserver = addObserver;
  _Ember.removeObserver = removeObserver;
  _Ember.PromiseProxyMixin = PromiseProxyMixin;
  // ****@ember/object/proxy****
  _Ember.ObjectProxy = ObjectProxy;
  // ****@ember/routing/-internals****
  _Ember.RouterDSL = DSLImpl;
  _Ember.controllerFor = controllerFor;
  _Ember.generateController = generateController;
  _Ember.generateControllerFactory = generateControllerFactory;
  _Ember.HashLocation = HashLocation;
  // ****@ember/routing/history-location****
  _Ember.HistoryLocation = HistoryLocation;
  // ****@ember/routing/none-location****
  _Ember.NoneLocation = NoneLocation;
  // ****@ember/routing/route****
  _Ember.Route = Route;
  // ****@ember/routing/router****
  _Ember.Router = EmberRouter;
  // // ****@ember/runloop****
  _Ember.run = run;
  _Ember.Service = Service;
  // ****@ember/utils****
  _Ember.compare = compare;
  _Ember.isBlank = isBlank;
  _Ember.isEmpty = isEmpty;
  _Ember.isEqual = isEqual;
  _Ember.isNone = isNone;
  _Ember.isPresent = isPresent;
  _Ember.typeOf = typeOf;
  _Ember.VERSION = Version;
  _Ember.ViewUtils = {
    // ****@ember/-internals/views****
    getChildViews: getChildViews,
    getElementView: getElementView,
    getRootViews: getRootViews,
    getViewBounds: getViewBounds,
    getViewBoundingClientRect: getViewBoundingClientRect,
    getViewClientRects: getViewClientRects,
    getViewElement: getViewElement,
    isSimpleClick: isSimpleClick,
    // ****@ember/-internals/glimmer****
    isSerializationFirstNode
  };
  _Ember._getComponentTemplate = getComponentTemplate;
  _Ember._helperManagerCapabilities = helperCapabilities;
  _Ember._setComponentTemplate = setComponentTemplate;
  _Ember._setHelperManager = setHelperManager;
  _Ember._setModifierManager = setModifierManager;
  _Ember._templateOnlyComponent = templateOnlyComponent;
  _Ember._invokeHelper = invokeHelper;
  _Ember._hash = hash;
  _Ember._array = array;
  _Ember._concat = concat;
  _Ember._get = get$1;
  _Ember._on = on$1;
  _Ember._fn = fn;
  _Ember._Backburner = Backburner;
  // // ****@ember/controller, @ember/service****
  /**
    Namespace for injection helper methods.
     @class inject
    @namespace Ember
    @static
    @public
  */
  function inject$1() {
    // uses `globalThis` to avoid clobbering with `Ember.Object` in TS namespace
    (isDevelopingApp() && !(false) && assert(`Injected properties must be created through helpers, see '${globalThis.Object.keys(inject$1).map(k => `'inject.${k}'`).join(' or ')}'`));
  }
  _Ember.inject = inject$1;
  // ****@ember/controller****
  inject$1.controller = inject;
  // ****@ember/service****
  inject$1.service = service;
  _Ember.__loader = {
    get require() {
      return globalThis.require;
    },
    get define() {
      return globalThis.define;
    },
    get registry() {
      let g = globalThis;
      return g.requirejs?.entries ?? g.require.entries;
    }
  };

  // ------------------------------------------------------------------------ //
  // These properties are assigned to the namespace with getters (and, in some
  // cases setters) with `Object.defineProperty` below.
  // ------------------------------------------------------------------------ //

  // ****@ember/-internals/environment****

  /**
    A function may be assigned to `Ember.onerror` to be called when Ember
    internals encounter an error. This is useful for specialized error handling
    and reporting code.
     ```javascript
     Ember.onerror = function(error) {
      const payload = {
        stack: error.stack,
        otherInformation: 'whatever app state you want to provide'
      };
       fetch('/report-error', {
        method: 'POST',
        body: JSON.stringify(payload)
      });
    };
    ```
     Internally, `Ember.onerror` is used as Backburner's error handler.
     @event onerror
    @for Ember
    @param {Error} error the error object
    @public
  */
  // ****@ember/-internals/error-handling****

  /**
    Whether searching on the global for new Namespace instances is enabled.
     This is only exported here as to not break any addons.  Given the new
    visit API, you will have issues if you treat this as a indicator of
    booted.
     Internally this is only exposing a flag in Namespace.
     @property BOOTED
    @for Ember
    @type Boolean
    @private
  */

  /**
    Global hash of shared templates. This will automatically be populated
    by the build tools so that you can store your Handlebars templates in
    separate files that get loaded into JavaScript at buildtime.
     @property TEMPLATES
    @for Ember
    @type Object
    @private
  */
})(Ember || (Ember = {})); // This syntax is not reliably implemented by TypeScript transpilers, but
// we need to re-export the`RSVP` *namespace* for type compatibility.
// To achieve this, we use a type-only `declare namespace` block to get the
// types to behave correctly, and separately set the `RSVP` property on the
// `Ember` object dynamically. (The types behave correctly because of
// namespace merging semantics.)
// eslint-disable-next-line @typescript-eslint/no-namespace
Reflect.set(Ember, 'RSVP', RSVP);
Object.defineProperty(Ember, 'ENV', {
  get: getENV,
  enumerable: false
});
Object.defineProperty(Ember, 'lookup', {
  get: getLookup,
  set: setLookup,
  enumerable: false
});
Object.defineProperty(Ember, 'onerror', {
  get: getOnerror,
  set: setOnerror,
  enumerable: false
});
Object.defineProperty(Ember, 'testing', {
  get: isTesting,
  set: setTesting,
  enumerable: false
});
Object.defineProperty(Ember, 'BOOTED', {
  configurable: false,
  enumerable: false,
  get: isSearchDisabled,
  set: setSearchDisabled
});
Object.defineProperty(Ember, 'TEMPLATES', {
  get: getTemplates,
  set: setTemplates,
  configurable: false,
  enumerable: false
});
Object.defineProperty(Ember, 'TEMPLATES', {
  get: getTemplates,
  set: setTemplates,
  configurable: false,
  enumerable: false
});

// ****@ember/debug****
Object.defineProperty(Ember, 'testing', {
  get: isTesting,
  set: setTesting,
  enumerable: false
});
runLoadHooks('Ember.Application', Application);
let EmberHandlebars = {
  template: templateFactory,
  Utils: {
    escapeExpression
  }
};
let EmberHTMLBars = {
  template: templateFactory
};
function defineEmberTemplateCompilerLazyLoad(key) {
  Object.defineProperty(Ember, key, {
    configurable: true,
    enumerable: true,
    get() {
      if (__emberTemplateCompiler) {
        EmberHTMLBars.precompile = EmberHandlebars.precompile = __emberTemplateCompiler.precompile;
        EmberHTMLBars.compile = EmberHandlebars.compile = compileTemplate;
        Object.defineProperty(Ember, 'HTMLBars', {
          configurable: true,
          writable: true,
          enumerable: true,
          value: EmberHTMLBars
        });
        Object.defineProperty(Ember, 'Handlebars', {
          configurable: true,
          writable: true,
          enumerable: true,
          value: EmberHandlebars
        });
      }
      return key === 'Handlebars' ? EmberHandlebars : EmberHTMLBars;
    }
  });
}
defineEmberTemplateCompilerLazyLoad('HTMLBars');
defineEmberTemplateCompilerLazyLoad('Handlebars');

// do this to ensure that Ember.Test is defined properly on the global
// if it is present.
function defineEmberTestingLazyLoad(key) {
  Object.defineProperty(Ember, key, {
    configurable: true,
    enumerable: true,
    get() {
      if (_impl) {
        let {
          Test,
          Adapter,
          QUnitAdapter,
          setupForTesting
        } = _impl;

        // @ts-expect-error We should not do this
        Test.Adapter = Adapter;
        // @ts-expect-error We should not do this
        Test.QUnitAdapter = QUnitAdapter;
        Object.defineProperty(Ember, 'Test', {
          configurable: true,
          writable: true,
          enumerable: true,
          value: Test
        });
        Object.defineProperty(Ember, 'setupForTesting', {
          configurable: true,
          writable: true,
          enumerable: true,
          value: setupForTesting
        });
        return key === 'Test' ? Test : setupForTesting;
      }
      return undefined;
    }
  });
}
defineEmberTestingLazyLoad('Test');
defineEmberTestingLazyLoad('setupForTesting');

// @ts-expect-error Per types, runLoadHooks requires a second parameter. Should we loosen types?
runLoadHooks('Ember');
const doNotUseThis = Ember;

export { doNotUseThis as default };
