import { d as b, r as _, w as y, c as h, o as r, h as g, i as p, n as v, a as k, m as q, t as w, j as m, s as $, F as P, e as B, f as N, _ as j, g as z } from "../_plugin-vue_export-helper-CG_vnhyz.js";
function L(o, n = E) {
  const i = [...o];
  for (let a = i.length - 1; a > 0; a--) {
    const e = n() % (a + 1);
    [i[a], i[e]] = [i[e], i[a]];
  }
  return i;
}
function E() {
  return Math.floor(Math.random() * 2 ** 32);
}
const M = ["aria-checked", "tabindex"], Q = { key: 0 }, S = /* @__PURE__ */ b({
  __name: "QCUChoice",
  props: {
    choice: {},
    maxChoicesPerLine: {},
    imageClass: {},
    nameClass: {},
    checked: { type: Boolean },
    focusable: { type: Boolean }
  },
  emits: ["check", "checkNext", "checkPrevious"],
  setup(o) {
    const n = o, i = _();
    y(() => {
      var e;
      n.checked && ((e = i.value) == null || e.focus());
    });
    const a = `qcu-image-choice-${(n.choice.response ?? n.choice.name).replace(/[^\w\-_:.]/g, "")}`;
    return (e, s) => (r(), h("li", {
      ref_key: "el",
      ref: i,
      role: "radio",
      class: v(["qcu-image__choice", `qcu-image__choice--max${n.maxChoicesPerLine}`]),
      "aria-labelledby": a,
      "aria-checked": e.checked,
      tabindex: e.focusable ? 0 : -1,
      onClick: s[0] || (s[0] = p((c) => e.$emit("check"), ["stop"])),
      onKeydown: [
        s[1] || (s[1] = g(p((c) => e.$emit("check"), ["stop", "prevent"]), ["enter", "space"])),
        s[2] || (s[2] = g(p((c) => e.$emit("checkPrevious"), ["stop", "prevent"]), ["up", "left"])),
        s[3] || (s[3] = g(p((c) => e.$emit("checkNext"), ["stop", "prevent"]), ["down", "right"]))
      ]
    }, [
      e.choice.image ? (r(), h("figure", Q, [
        k("img", q(e.choice.image, {
          class: e.imageClass,
          alt: ""
        }), null, 16),
        k("figcaption", {
          class: v(e.nameClass),
          id: a
        }, w(e.choice.name), 3)
      ])) : (r(), h("span", {
        key: 1,
        id: a
      }, w(e.choice.name), 1))
    ], 42, M));
  }
}), U = ["aria-label"], F = /* @__PURE__ */ b({
  __name: "QCUImage.ce",
  props: {
    props: { type: Object }
  },
  emits: ["answer"],
  setup(o, { emit: n }) {
    const i = n, a = m(() => o.props.maxChoicesPerLine ?? 5), e = m(() => o.props.hideChoicesName ? "sr-only" : ""), s = m(() => o.props.imageChoicesSize ?? "icon"), c = m(() => o.props.orderChoices ? o.props.choices : L(o.props.choices)), t = $(), C = m(() => t.value ?? c.value[0]);
    function d(l) {
      l === -1 ? t.value = c.value[c.value.length - 1] : l === c.value.length ? t.value = c.value[0] : t.value = c.value[l], i("answer", t.value.response ?? t.value.name);
    }
    return (l, O) => (r(), h("ul", {
      role: "radiogroup",
      class: "qcu-image",
      "aria-label": l.props.name
    }, [
      (r(!0), h(P, null, B(c.value, (f, u) => (r(), N(S, {
        choice: f,
        "max-choices-per-line": a.value,
        key: u,
        "name-class": e.value,
        "image-class": s.value,
        checked: f === t.value,
        focusable: f === C.value,
        onCheck: (x) => d(u),
        onCheckNext: (x) => d(u + 1),
        onCheckPrevious: (x) => d(u - 1)
      }, null, 8, ["choice", "max-choices-per-line", "name-class", "image-class", "checked", "focusable", "onCheck", "onCheckNext", "onCheckPrevious"]))), 128))
    ], 8, U));
  }
}), I = '*{box-sizing:border-box}ul,li{padding:0;margin:0}li{list-style:none}.qcu-image{display:flex;width:100%;flex-wrap:wrap;justify-content:space-around;font-size:110%;font-family:Open Sans,Arial,sans-serif}.qcu-image__choice{display:flex;position:relative;flex-direction:column;align-items:center;justify-content:center;margin:1em;border:solid 2px #c1c7d0;border-radius:10px;background:#fff;width:calc(20% - 2em);min-width:120px}.qcu-image__choice--max4{width:calc(25% - 2em);min-width:175px}.qcu-image__choice--max3{width:calc(33% - 2em)}.qcu-image__choice--max2{width:calc(50% - 2em);min-width:300px}.qcu-image__choice--max1{width:calc(100% - 4em);min-width:300px}.qcu-image__choice:hover{cursor:pointer;box-shadow:0 3px 10px #0000001a;transform:scale(1.04);transition:transform ease-in .1s}.qcu-image__choice:focus{outline:none;box-shadow:5px 5px #ffbe00,-5px 5px #ffbe00,5px -5px #ffbe00,-5px -5px #ffbe00}.qcu-image__choice[aria-checked=true]{border-color:#3d68ff}.qcu-image__choice[aria-checked=true]:after{content:"";position:absolute;width:100%;height:100%;background:#3d68ff4d;border-radius:10px;z-index:3}.qcu-image__choice figure{width:100%;padding:0 10px;margin:3px;text-align:center}.qcu-image__choice figure img{max-width:100%;height:auto}.qcu-image__choice figure img.icon{max-height:100px;width:auto}.qcu-image__choice figure img.medium{max-width:50%;height:auto}.qcu-image__choice figure figcaption{margin:1ex}.sr-only{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0}', K = /* @__PURE__ */ j(F, [["styles", [I]]]);
window.customElements.define("qcu-image", z(K));
