import { template as template_19b914da891b4ec3969396efb30f2ced } from "@ember/template-compiler";
export default template_19b914da891b4ec3969396efb30f2ced(`
  <div class="card-wrapper">
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
