import { checkWaiters } from '../test/waiters.js';
import '../../../@ember/-internals/runtime/lib/mixins/registry_proxy.js';
import '../../../@ember/-internals/runtime/lib/mixins/container_proxy.js';
import '../../../@ember/-internals/runtime/lib/mixins/comparable.js';
import '../../../@ember/-internals/runtime/lib/mixins/action_handler.js';
import '../../../@ember/-internals/runtime/lib/mixins/-proxy.js';
import '../../../@ember/enumerable/mutable.js';
import '../../../@ember/-internals/runtime/lib/mixins/target_action_support.js';
import '../../../@ember/-internals/runtime/lib/ext/rsvp.js';
import { _hasScheduledTimers, _getCurrentRunLoop, run } from '../../../@ember/runloop/index.js';
import { pendingRequests } from '../test/pending_requests.js';
import '../../../@ember/debug/index.js';
import EmberRouter from '../../../@ember/routing/router.js';
import { isDevelopingApp } from '@embroider/macros';
import { assert } from '../../../@ember/debug/lib/assert.js';
import { R as RSVP } from '../../../shared-chunks/rsvp-DaQAFb0W.js';

/**
@module ember
*/
function wait(app, value) {
  return new RSVP.Promise(function (resolve) {
    (isDevelopingApp() && !(app.__container__) && assert('[BUG] Missing container', app.__container__));
    const router = app.__container__.lookup('router:main');
    (isDevelopingApp() && !(router instanceof EmberRouter) && assert('[BUG] Expected router:main to be a subclass of Ember Router', router instanceof EmberRouter)); // Every 10ms, poll for the async thing to have finished
    let watcher = setInterval(() => {
      // 1. If the router is loading, keep polling
      let routerIsLoading = router._routerMicrolib && Boolean(router._routerMicrolib.activeTransition);
      if (routerIsLoading) {
        return;
      }

      // 2. If there are pending Ajax requests, keep polling
      if (pendingRequests()) {
        return;
      }

      // 3. If there are scheduled timers or we are inside of a run loop, keep polling
      if (_hasScheduledTimers() || _getCurrentRunLoop()) {
        return;
      }
      if (checkWaiters()) {
        return;
      }

      // Stop polling
      clearInterval(watcher);

      // Synchronously resolve the promise
      run(null, resolve, value);
    }, 10);
  });
}

export { wait as default };
