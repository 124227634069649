import { run as run$1, _getCurrentRunLoop } from '../../../@ember/runloop/index.js';

function run(fn) {
  if (!_getCurrentRunLoop()) {
    return run$1(fn);
  } else {
    return fn();
  }
}

export { run as default };
