import { o as objectAt, g as get, l as endPropertyChanges, P as PROXY_CONTENT, c as computed, m as beginPropertyChanges } from '../../shared-chunks/cache-Djf2I3Za.js';
import '../-internals/meta/lib/meta.js';
import '../debug/index.js';
import '../../@glimmer/validator/index.js';
import '../../shared-chunks/mandatory-setter-BiXq-dpN.js';
import { isDevelopingApp } from '@embroider/macros';
import '../../@glimmer/destroyable/index.js';
import '../../@glimmer/manager/index.js';
import { s as set } from '../../shared-chunks/property_set-DS4X3Soy.js';
import { r as replaceInNativeArray, a as replace } from '../../shared-chunks/array-QJXVmB_W.js';
import '../../shared-chunks/env-CwR5CFCu.js';
import '../object/index.js';
import Mixin from '../object/mixin.js';
import Enumerable from '../enumerable/index.js';
import MutableEnumerable from '../enumerable/mutable.js';
import typeOf from '../utils/lib/type-of.js';
import compare from '../utils/lib/compare.js';
import Observable from '../object/observable.js';
import { setEmberArray, isEmberArray } from './-internals.js';
export { default as makeArray } from './lib/make-array.js';
import { assert } from '../debug/lib/assert.js';

/**
@module @ember/array
*/

const EMPTY_ARRAY = Object.freeze([]);
const identityFunction = item => item;
function uniqBy(array, keyOrFunc = identityFunction) {
  (isDevelopingApp() && !(isArray(array)) && assert(`first argument passed to \`uniqBy\` should be array`, isArray(array)));
  let ret = A();
  let seen = new Set();
  let getter = typeof keyOrFunc === 'function' ? keyOrFunc : item => get(item, keyOrFunc);
  array.forEach(item => {
    let val = getter(item);
    if (!seen.has(val)) {
      seen.add(val);
      ret.push(item);
    }
  });
  return ret;
}
function iter(...args) {
  let valueProvided = args.length === 2;
  let [key, value] = args;
  return valueProvided ? item => value === get(item, key) : item => Boolean(get(item, key));
}
function findIndex(array, predicate, startAt) {
  let len = array.length;
  for (let index = startAt; index < len; index++) {
    // SAFETY: Because we're checking the index this value should always be set.
    let item = objectAt(array, index);
    if (predicate(item, index, array)) {
      return index;
    }
  }
  return -1;
}
function find(array, callback, target = null) {
  let predicate = callback.bind(target);
  let index = findIndex(array, predicate, 0);
  return index === -1 ? undefined : objectAt(array, index);
}
function any(array, callback, target = null) {
  let predicate = callback.bind(target);
  return findIndex(array, predicate, 0) !== -1;
}
function every(array, callback, target = null) {
  let cb = callback.bind(target);
  let predicate = (item, index, array) => !cb(item, index, array);
  return findIndex(array, predicate, 0) === -1;
}
function indexOf(array, val, startAt = 0, withNaNCheck) {
  let len = array.length;
  if (startAt < 0) {
    startAt += len;
  }

  // SameValueZero comparison (NaN !== NaN)
  let predicate = withNaNCheck && val !== val ? item => item !== item : item => item === val;
  return findIndex(array, predicate, startAt);
}
function removeAt(array, index, len) {
  (isDevelopingApp() && !(index > -1 && index < array.length) && assert(`\`removeAt\` index provided is out of range`, index > -1 && index < array.length));
  replace(array, index, len ?? 1, EMPTY_ARRAY);
  return array;
}
function insertAt(array, index, item) {
  (isDevelopingApp() && !(index > -1 && index <= array.length) && assert(`\`insertAt\` index provided is out of range`, index > -1 && index <= array.length));
  replace(array, index, 0, [item]);
  return item;
}

/**
  Returns true if the passed object is an array or Array-like.

  Objects are considered Array-like if any of the following are true:

    - the object is a native Array
    - the object has an objectAt property
    - the object is an Object, and has a length property

  Unlike `typeOf` this method returns true even if the passed object is
  not formally an array but appears to be array-like (i.e. implements `Array`)

  ```javascript
  import { isArray } from '@ember/array';
  import ArrayProxy from '@ember/array/proxy';

  isArray();                                      // false
  isArray([]);                                    // true
  isArray(ArrayProxy.create({ content: [] }));    // true
  ```

  @method isArray
  @static
  @for @ember/array
  @param {Object} obj The object to test
  @return {Boolean} true if the passed object is an array or Array-like
  @public
*/
function isArray(obj) {
  if (isDevelopingApp() && typeof obj === 'object' && obj !== null) {
    // SAFETY: Property read checks are safe if it's an object
    let possibleProxyContent = obj[PROXY_CONTENT];
    if (possibleProxyContent !== undefined) {
      obj = possibleProxyContent;
    }
  }

  // SAFETY: Property read checks are safe if it's an object
  if (!obj || obj.setInterval) {
    return false;
  }
  if (Array.isArray(obj) || EmberArray.detect(obj)) {
    return true;
  }
  let type = typeOf(obj);
  if ('array' === type) {
    return true;
  }

  // SAFETY: Property read checks are safe if it's an object
  let length = obj.length;
  if (typeof length === 'number' && length === length && 'object' === type) {
    return true;
  }
  return false;
}

/*
  This allows us to define computed properties that are not enumerable.
  The primary reason this is important is that when `NativeArray` is
  applied to `Array.prototype` we need to ensure that we do not add _any_
  new enumerable properties.
*/
function nonEnumerableComputed(callback) {
  let property = computed(callback);
  property.enumerable = false;
  return property;
}
function mapBy(key) {
  return this.map(next => get(next, key));
}

// ..........................................................
// ARRAY
//
/**
  This mixin implements Observer-friendly Array-like behavior. It is not a
  concrete implementation, but it can be used up by other classes that want
  to appear like arrays.

  For example, ArrayProxy is a concrete class that can be instantiated to
  implement array-like behavior. This class uses the Array Mixin by way of
  the MutableArray mixin, which allows observable changes to be made to the
  underlying array.

  This mixin defines methods specifically for collections that provide
  index-ordered access to their contents. When you are designing code that
  needs to accept any kind of Array-like object, you should use these methods
  instead of Array primitives because these will properly notify observers of
  changes to the array.

  Although these methods are efficient, they do add a layer of indirection to
  your application so it is a good idea to use them only when you need the
  flexibility of using both true JavaScript arrays and "virtual" arrays such
  as controllers and collections.

  You can use the methods defined in this module to access and modify array
  contents in an observable-friendly way. You can also be notified whenever
  the membership of an array changes by using `.observes('myArray.[]')`.

  To support `EmberArray` in your own class, you must override two
  primitives to use it: `length()` and `objectAt()`.

  @class EmberArray
  @uses Enumerable
  @since Ember 0.9.0
  @public
*/

const EmberArray = Mixin.create(Enumerable, {
  init() {
    this._super(...arguments);
    setEmberArray(this);
  },
  objectsAt(indexes) {
    return indexes.map(idx => objectAt(this, idx));
  },
  '[]': nonEnumerableComputed({
    get() {
      return this;
    },
    set(_key, value) {
      this.replace(0, this.length, value);
      return this;
    }
  }),
  firstObject: nonEnumerableComputed(function () {
    return objectAt(this, 0);
  }).readOnly(),
  lastObject: nonEnumerableComputed(function () {
    return objectAt(this, this.length - 1);
  }).readOnly(),
  // Add any extra methods to EmberArray that are native to the built-in Array.
  slice(beginIndex = 0, endIndex) {
    let ret = A();
    let length = this.length;
    if (beginIndex < 0) {
      beginIndex = length + beginIndex;
    }
    let validatedEndIndex;
    if (endIndex === undefined || endIndex > length) {
      validatedEndIndex = length;
    } else if (endIndex < 0) {
      validatedEndIndex = length + endIndex;
    } else {
      validatedEndIndex = endIndex;
    }
    while (beginIndex < validatedEndIndex) {
      ret[ret.length] = objectAt(this, beginIndex++);
    }
    return ret;
  },
  indexOf(object, startAt) {
    return indexOf(this, object, startAt, false);
  },
  lastIndexOf(object, startAt) {
    let len = this.length;
    if (startAt === undefined || startAt >= len) {
      startAt = len - 1;
    }
    if (startAt < 0) {
      startAt += len;
    }
    for (let idx = startAt; idx >= 0; idx--) {
      if (objectAt(this, idx) === object) {
        return idx;
      }
    }
    return -1;
  },
  forEach(callback, target = null) {
    (isDevelopingApp() && !(typeof callback === 'function') && assert('`forEach` expects a function as first argument.', typeof callback === 'function'));
    let length = this.length;
    for (let index = 0; index < length; index++) {
      let item = this.objectAt(index);
      callback.call(target, item, index, this);
    }
    return this;
  },
  getEach: mapBy,
  setEach(key, value) {
    return this.forEach(item => set(item, key, value));
  },
  map(callback, target = null) {
    (isDevelopingApp() && !(typeof callback === 'function') && assert('`map` expects a function as first argument.', typeof callback === 'function'));
    let ret = A();
    this.forEach((x, idx, i) => ret[idx] = callback.call(target, x, idx, i));
    return ret;
  },
  mapBy,
  filter(callback, target = null) {
    (isDevelopingApp() && !(typeof callback === 'function') && assert('`filter` expects a function as first argument.', typeof callback === 'function'));
    let ret = A();
    this.forEach((x, idx, i) => {
      if (callback.call(target, x, idx, i)) {
        ret.push(x);
      }
    });
    return ret;
  },
  reject(callback, target = null) {
    (isDevelopingApp() && !(typeof callback === 'function') && assert('`reject` expects a function as first argument.', typeof callback === 'function'));
    return this.filter(function () {
      // @ts-expect-error TS doesn't like us using arguments like this
      return !callback.apply(target, arguments);
    });
  },
  filterBy() {
    // @ts-expect-error TS doesn't like the ...arguments spread here.
    return this.filter(iter(...arguments));
  },
  rejectBy() {
    // @ts-expect-error TS doesn't like the ...arguments spread here.
    return this.reject(iter(...arguments));
  },
  find(callback, target = null) {
    (isDevelopingApp() && !(typeof callback === 'function') && assert('`find` expects a function as first argument.', typeof callback === 'function'));
    return find(this, callback, target);
  },
  findBy() {
    // @ts-expect-error TS doesn't like the ...arguments spread here.
    let callback = iter(...arguments);
    return find(this, callback);
  },
  every(callback, target = null) {
    (isDevelopingApp() && !(typeof callback === 'function') && assert('`every` expects a function as first argument.', typeof callback === 'function'));
    return every(this, callback, target);
  },
  isEvery() {
    // @ts-expect-error TS doesn't like the ...arguments spread here.
    let callback = iter(...arguments);
    return every(this, callback);
  },
  any(callback, target = null) {
    (isDevelopingApp() && !(typeof callback === 'function') && assert('`any` expects a function as first argument.', typeof callback === 'function'));
    return any(this, callback, target);
  },
  isAny() {
    // @ts-expect-error TS doesn't like us using arguments like this
    let callback = iter(...arguments);
    return any(this, callback);
  },
  // FIXME: When called without initialValue, behavior does not match native behavior
  reduce(callback, initialValue) {
    (isDevelopingApp() && !(typeof callback === 'function') && assert('`reduce` expects a function as first argument.', typeof callback === 'function'));
    let ret = initialValue;
    this.forEach(function (item, i) {
      ret = callback(ret, item, i, this);
    }, this);
    return ret;
  },
  invoke(methodName, ...args) {
    let ret = A();

    // SAFETY: This is not entirely safe and the code will not work with Ember proxies
    this.forEach(item => ret.push(item[methodName]?.(...args)));
    return ret;
  },
  toArray() {
    return this.map(item => item);
  },
  compact() {
    return this.filter(value => value != null);
  },
  includes(object, startAt) {
    return indexOf(this, object, startAt, true) !== -1;
  },
  sortBy() {
    let sortKeys = arguments;
    return this.toArray().sort((a, b) => {
      for (let i = 0; i < sortKeys.length; i++) {
        let key = sortKeys[i];
        let propA = get(a, key);
        let propB = get(b, key);
        // return 1 or -1 else continue to the next sortKey
        let compareValue = compare(propA, propB);
        if (compareValue) {
          return compareValue;
        }
      }
      return 0;
    });
  },
  uniq() {
    return uniqBy(this);
  },
  uniqBy(key) {
    return uniqBy(this, key);
  },
  without(value) {
    if (!this.includes(value)) {
      return this; // nothing to do
    }

    // SameValueZero comparison (NaN !== NaN)
    let predicate = value === value ? item => item !== value : item => item === item;
    return this.filter(predicate);
  }
});

/**
  This mixin defines the API for modifying array-like objects. These methods
  can be applied only to a collection that keeps its items in an ordered set.
  It builds upon the Array mixin and adds methods to modify the array.
  One concrete implementations of this class include ArrayProxy.

  It is important to use the methods in this class to modify arrays so that
  changes are observable. This allows the binding system in Ember to function
  correctly.


  Note that an Array can change even if it does not implement this mixin.
  For example, one might implement a SparseArray that cannot be directly
  modified, but if its underlying enumerable changes, it will change also.

  @class MutableArray
  @uses EmberArray
  @uses MutableEnumerable
  @public
*/

const MutableArray = Mixin.create(EmberArray, MutableEnumerable, {
  clear() {
    let len = this.length;
    if (len === 0) {
      return this;
    }
    this.replace(0, len, EMPTY_ARRAY);
    return this;
  },
  insertAt(idx, object) {
    insertAt(this, idx, object);
    return this;
  },
  removeAt(start, len) {
    return removeAt(this, start, len);
  },
  pushObject(obj) {
    return insertAt(this, this.length, obj);
  },
  pushObjects(objects) {
    this.replace(this.length, 0, objects);
    return this;
  },
  popObject() {
    let len = this.length;
    if (len === 0) {
      return null;
    }
    let ret = objectAt(this, len - 1);
    this.removeAt(len - 1, 1);
    return ret;
  },
  shiftObject() {
    if (this.length === 0) {
      return null;
    }
    let ret = objectAt(this, 0);
    this.removeAt(0);
    return ret;
  },
  unshiftObject(obj) {
    return insertAt(this, 0, obj);
  },
  unshiftObjects(objects) {
    this.replace(0, 0, objects);
    return this;
  },
  reverseObjects() {
    let len = this.length;
    if (len === 0) {
      return this;
    }
    let objects = this.toArray().reverse();
    this.replace(0, len, objects);
    return this;
  },
  setObjects(objects) {
    if (objects.length === 0) {
      return this.clear();
    }
    let len = this.length;
    this.replace(0, len, objects);
    return this;
  },
  removeObject(obj) {
    let loc = this.length || 0;
    while (--loc >= 0) {
      let curObject = objectAt(this, loc);
      if (curObject === obj) {
        this.removeAt(loc);
      }
    }
    return this;
  },
  removeObjects(objects) {
    beginPropertyChanges();
    for (let i = objects.length - 1; i >= 0; i--) {
      // SAFETY: Due to the loop structure we know this will always exist.
      this.removeObject(objects[i]);
    }
    endPropertyChanges();
    return this;
  },
  addObject(obj) {
    let included = this.includes(obj);
    if (!included) {
      this.pushObject(obj);
    }
    return this;
  },
  addObjects(objects) {
    beginPropertyChanges();
    objects.forEach(obj => this.addObject(obj));
    endPropertyChanges();
    return this;
  }
});

/**
  Creates an `Ember.NativeArray` from an Array-like object.
  Does not modify the original object's contents.

  Example

  ```app/components/my-component.js
  import Component from '@ember/component';
  import { A } from '@ember/array';

  export default Component.extend({
    tagName: 'ul',
    classNames: ['pagination'],

    init() {
      this._super(...arguments);

      if (!this.get('content')) {
        this.set('content', A());
        this.set('otherContent', A([1,2,3]));
      }
    }
  });
  ```

  @method A
  @static
  @for @ember/array
  @return {Ember.NativeArray}
  @public
*/

// Add Ember.Array to Array.prototype. Remove methods with native
// implementations and supply some more optimized versions of generic methods
// because they are so common.
/**
@module ember
*/

/**
 * The final definition of NativeArray removes all native methods. This is the list of removed methods
 * when run in Chrome 106.
 */

/**
 * These additional items must be redefined since `Omit` causes methods that return `this` to return the
 * type at the time of the Omit.
 */

// This is the same as MutableArray, but removes the actual native methods that exist on Array.prototype.

/**
  The NativeArray mixin contains the properties needed to make the native
  Array support MutableArray and all of its dependent APIs.

  @class Ember.NativeArray
  @uses MutableArray
  @uses Observable
  @public
*/

let NativeArray = Mixin.create(MutableArray, Observable, {
  objectAt(idx) {
    return this[idx];
  },
  // primitive for array support.
  replace(start, deleteCount, items = EMPTY_ARRAY) {
    (isDevelopingApp() && !(Array.isArray(items)) && assert('The third argument to replace needs to be an array.', Array.isArray(items)));
    replaceInNativeArray(this, start, deleteCount, items);
    return this;
  }
});

// Remove any methods implemented natively so we don't override them
const ignore = ['length'];
NativeArray.keys().forEach(methodName => {
  // SAFETY: It's safe to read unknown properties from an object
  if (Array.prototype[methodName]) {
    ignore.push(methodName);
  }
});
NativeArray = NativeArray.without(...ignore);
let A;
A = function (arr) {
  (isDevelopingApp() && !(!(this instanceof A)) && assert('You cannot create an Ember Array with `new A()`, please update to calling A as a function: `A()`', !(this instanceof A)));
  if (isEmberArray(arr)) {
    // SAFETY: If it's a true native array and it is also an EmberArray then it should be an Ember NativeArray
    return arr;
  } else {
    // SAFETY: This will return an NativeArray but TS can't infer that.
    return NativeArray.apply(arr ?? []);
  }
};

export { A, MutableArray, NativeArray, EmberArray as default, isArray, removeAt, uniqBy };
